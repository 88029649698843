import { getCustomError, CustomError } from 'utils/api/error';
import API from 'utils/api';
import { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getRequestConfig } from 'utils/api/requestConfig';
import { Employee, EmployeeStateActions as MembershipStateActions } from 'models/employee';
import { EmployeeData } from 'utils/graphql/queries/employee';

export const useGetMembership = () => {
  const [data, setData] = useState<EmployeeData | undefined>(undefined);
  const [getMembershipLoading, setGetMembershipLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const getMembership = async (membershipId: string) => {
    setGetMembershipLoading(true);
    return axios.get(
      `memberships/${membershipId}`,
      getRequestConfig(),
    )
      .then((response: AxiosResponse<Employee>) => {
        const employeeData: EmployeeData = { employee: response.data };
        setData(employeeData);
        setGetMembershipLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setGetMembershipLoading(false);
      });
  };

  return {
    getMembership,
    getMembershipLoading,
    errorMsg,
    hasError,
    data,
  };

};

export const useApproveMembership = () => {

  const [approveIsLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const approveRequest = async (membershipId: string) => {
    setLoading(true);
    return API.put(
      `memberships/${membershipId}/state`,
      { action: MembershipStateActions.APPROVE },
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setLoading(false);
      });
  };

  return {
    approveRequest,
    approveIsLoading,
    errorMsg,
    hasError,
  };

};

export const useRejectMembership = () => {

  const [rejectIsLoading, setRejectIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const rejectRequest = async (membershipId: string) => {
    setRejectIsLoading(true);
    return API.put(
      `memberships/${membershipId}/state`,
      { action: MembershipStateActions.REJECT },
    )
      .then(() => {
        setRejectIsLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setRejectIsLoading(false);
      });
  };

  return {
    rejectRequest,
    rejectIsLoading,
    errorMsg,
    hasError,
  };

};

export const useCancelMembership = () => {

  const [cancelIsLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const cancelRequest = async (membershipId: string, date: string | null, reason: string, description: string | null) => {
    setLoading(true);
    await axios.put(
      `memberships/${membershipId}/cancel`,
      JSON.stringify({
        date: date,
        reason: reason,
        description: description,
      }),
      getRequestConfig(),
    ).then(
      () => {
        setLoading(false);
      },
    ).catch((error) => {
      setHasError(true);
      errorMsg = getCustomError(error);
      setLoading(false);
    });
  };

  return {
    cancelRequest,
    cancelIsLoading,
    errorMsg,
    hasError,
  };
};

export const useBulkApproveMembership = () => {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const onBulkApproveRequest = async (
    companyId: string,
    membershipsIds: string[],
  ) => {
    setLoading(true);
    return API.put(`companies/${companyId}/memberships/approve`, {
      membershipsIds,
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setLoading(false);
      });
  };

  return {
    onBulkApproveRequest,
    isLoading,
    errorMsg,
    hasError,
  };
};


export const useBulkCancelMembership = () => {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const onBulkCancelRequest = async (
    companyId: string,
    membershipsIds: string[],
  ) => {
    setLoading(true);
    return API.put(`companies/${companyId}/memberships/cancel`, {
      membershipsIds,
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setLoading(false);
      });
  };

  return {
    onBulkCancelRequest,
    isLoading,
    errorMsg,
    hasError,
  };
};