import { TagManagerArgs } from 'react-gtm-module';
import i18next from 'i18n';

import { EmployeesTabsState, EmployeeState } from 'models/employee';
import { ITab, ITabsState, Language } from 'models/generic';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GTM_CONFIG: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  events: { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
};

export enum AuthFlow {
  REGISTER = 'register',
  PASSWORD = 'password',
}

export const ACTIVE_TAB: ITab = {
  generalState: EmployeeState.ACTIVE,
  status: [
    EmployeeState.ACTIVE,
    EmployeeState.PENDING_ACTIVATION,
    EmployeeState.APPROVAL_REQUESTED,
    EmployeeState.PAUSED,
  ],
};

export const PENDING_APPROVAL_TAB: ITab =  {
  generalState: EmployeeState.PENDING_APPROVAL,
  status: [EmployeeState.PENDING_APPROVAL],
};

export const PENDING_CANCELLATION_TAB: ITab = {
  generalState: EmployeeState.PENDING_CANCELATION,
  status: [
    EmployeeState.PENDING_CANCELATION,
    EmployeeState.CANCELLATION_REQUESTED,
  ],
};

export const EMPLOYEES_TABS: ITabsState = {
  tabs: [ACTIVE_TAB, PENDING_APPROVAL_TAB, PENDING_CANCELLATION_TAB],
  activeIndex: 0,
};

export const PAGINATION_ITEMS_PER_LIST_VALUES = ['10', '20', '30'];

export const EMPLOYEES_SORT_BY_VALUES = [
  { value: 'firstName asc,lastName asc', label: i18next.t('employees_page.list.sorting.name_asc') },
  { value: 'firstName desc,lastName desc', label: i18next.t('employees_page.list.sorting.name_desc') },
  { value: 'membership_startDate asc', label: i18next.t('employees_page.list.sorting.start_date_asc') },
  { value: 'membership_startDate desc', label: i18next.t('employees_page.list.sorting.start_date_desc') },
];

export const DEFAULT_LANGUAGE: Language = { code: 'en', name: 'English' };

export const LANGUAGES: Language[] = [
  { code: 'en', name: 'English' },
  { code: 'de',  name: 'Deutsch' },
  { code: 'fr', name: 'Français' },
  { code: 'it', name: 'Italiano' },
  { code: 'pt', name: 'Português' },
  { code: 'es', name: 'Español' },
  { code: 'nl', name: 'Nederlands' },
];

export const HRIS = 'hris' as const;
export const MARKETING = 'marketing' as const;
