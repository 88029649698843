import { MouseEventHandler, ReactNode, useEffect } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import './Modal.scss';

interface Props {
  children: ReactNode;
  className?: string;
  onClose: MouseEventHandler;
  heading: string;
  isOpen: boolean;
}

const Modal = ({
  children,
  className = '',
  onClose,
  heading,
  isOpen,
}: Props): JSX.Element | null => {
  useEffect(() => {
    if (isOpen) document.body.classList.add('modal-open');
    else document.body.classList.remove('modal-open');
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`modal-wrapper ${className}`}>
      <div className="modal">
        <div className="modal__header">
          <button className="modal__close" type="button" onClick={onClose}>
            <CloseIcon />
            Close
            {/* Text for accessibility */}
          </button>
          <h2 className="heading-4 pl-[8px]">{heading}</h2>
        </div>
        <div className="modal__content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
