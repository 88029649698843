import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { LanguageIcon, CheckIcon } from '@heroicons/react/20/solid';
import { useAppContext } from 'context/AppContext';
import i18n from 'i18n';
import { Language } from 'models/generic';
import { useState } from 'react';
import { DEFAULT_LANGUAGE, LANGUAGES } from 'utils/constants';
import languageChangeHandler from 'utils/translations';

export default function LanguageSelectorNew() {
  const [selected, setSelected] = useState(i18n.language);
  const { setLanguage: setAppProviderLanguage } = useAppContext();

  const setLanguage = (lang: string) => {
    setSelected(lang);
    languageChangeHandler(lang);
    setAppProviderLanguage(lang);
  };

  const getLanguageCodes = (): string[] => {
    return LANGUAGES.map((lang: Language) => lang.code);
  };

  const getLanguageByCode = (code: string): Language => {
    const language = LANGUAGES.find((lang: Language) => lang.code === code);
    return language || DEFAULT_LANGUAGE;
  };
  return (
    <Menu
      as="div"
      className="group flex gap-x-3 p-2 text-sm font-semibold leading-6 text-gray-400 cursor-pointer rounded-md hover:bg-gray-800"
    >
      
      <MenuButton className="group flex gap-x-3 w-[275px] text-sm font-semibold text-gray-400 items-center hover:text-white">
        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-white bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white p-1">
          <LanguageIcon className="w-3 h-3" />
        </span> 
        {getLanguageByCode(selected).name}
      </MenuButton>

      <MenuItems
        className="absolute z-10 w-[275px] sm:w-[254px] origin-bottom border border-grey-dark-500 rounded-md 
        bg-black-midnight-light-800 shadow-lg ring-opacity-5 transition 
        focus:outline-none data-[closed]:scale-95 
        data-[closed]:transform data-[closed]:opacity-0 
        data-[enter]:duration-100 data-[leave]:duration-75 
        data-[enter]:ease-out data-[leave]:ease-in -mt-[278px] -ml-1.5"
        transition
      >
        <div className="py-1">
          {getLanguageCodes().map((code) => (
            <MenuItem key={code}>
              <div
                className="flex items-center gap-x-3 px-4 data-[focus]:bg-black-midnight-light-500 data-[focus]:text-white-natural-50"
                onClick={() => setLanguage(code)}
              >
                <span className="text-sm font-normal text-white-natural-50 py-2">
                  {getLanguageByCode(code).name}
                </span>
                {selected === code && (
                  <CheckIcon className="h-5 w-5 text-grey-dark-500 ml-auto" />
                )}
              </div>
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
}
