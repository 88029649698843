import StartScreen from './Start';
import ProgressBar from 'components/atoms/Tailwind/ProgressBar';
import { IOnBoardingContext, OnBoardingContext } from './Onboarding';
import { useContext, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import Question from './Question';
import EndScreen from './End';
import { useNavigate } from 'react-router-dom';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { useTranslation } from 'react-i18next';

const onboardingEvents: ButtonLayerEvent = {
  item: 'OnBoarding Skipped',
  details: 'On Boarding Skipped',
  origin: 'OnBoarding',
};

function Main() {
  const {
    progress,
    currentQuestion,
    questions,
    answers,
    setProgress,
    currentScreen,
    submitAnswers,
  } = useContext<IOnBoardingContext>(OnBoardingContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    function calculateProgress() {
      const totalQuestion = questions.length;
      const currentProgress =
        currentScreen !== 'Start'
          ? ((currentQuestion + 1) / totalQuestion) * 100
          : 0;

      setProgress(currentProgress.toString() + '%');
    }
    calculateProgress();
  }, [currentQuestion, questions, answers, setProgress, currentScreen]);

  function showProgressBar() {
    return ['Start', 'Question'].includes(currentScreen);
  }

  function getCurrentScreen() {
    if (currentScreen === 'Start') {
      return <StartScreen />;
    } else if (currentScreen === 'End') {
      return <EndScreen />;
    }
    return <Question />;
  }

  function onSkip() {
    
    if (currentScreen !== 'Start') {
      onboardingEvents.details += ' On Question: ' + questions[currentQuestion];
      submitAnswers();
    }
    
    pushToButtonDataLayer(onboardingEvents);
    navigate('/home');
  }

  return (
    <div className='flex flex-col p-5 md:p-9 h-screen justify-between items-center'>
      {showProgressBar() && (
        <div className='px-5 md:px-9 w-screen' aria-hidden='true'>
          <ProgressBar width={progress} />
        </div>
      )}
      {getCurrentScreen()}
      {showProgressBar() && (
        <div
          className='flex items-center cursor-pointer w-screen justify-end px-5 md:px-9'
          onClick={onSkip}
        >
          <span className='text-sm xl:text-md text-blue-respect-500'>
            {t('onboarding.button.skip')}
          </span>
          <ArrowRightIcon
            className='text-blue-respect-500 h-4 w-4'
            aria-hidden='true'
          />
        </div>
      )}
    </div>
  );
}

export default Main;
