export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
export const DATE_MONTH_YEAR_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };
export const DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
};

export const formatDate = (date: Date): string => (
  new Intl.DateTimeFormat(localStorage.getItem('lng') ?? 'en', DATE_FORMAT_OPTIONS).format(date)
);

export const formatMonthYear = (date: Date): string => (
  new Intl.DateTimeFormat(localStorage.getItem('lng') ?? 'en', DATE_MONTH_YEAR_FORMAT_OPTIONS).format(date)
);


export const formatDateTime = (date: Date): string =>
  new Intl.DateTimeFormat(
    localStorage.getItem('lng') ?? 'en',
    DATE_TIME_FORMAT_OPTIONS,
  ).format(date);