import { useTranslation } from 'react-i18next';
import { Text } from '@urbansportsclub/components/Text';
import { Checkbox } from '@urbansportsclub/components/Checkbox';
import { Badge } from '@urbansportsclub/components/Badge';
import HRIntegrationCard, {
  HRIntegrationStages,
} from 'components/organisms/HRIntegrationCard/HRIntegrationCard';
import { Company } from 'models/company';
import ConnectNow from 'assets/images/6connect.png';
import {
  ArrowRightIcon,
} from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HRCardClicked } from 'tracking/HrTracking';

interface IHRIntegrationProps {
  company?: Company;
  reloadCompany: () => void;
}

function FindOutMore(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex w-full justify-between items-center sm:items-end sm:justify-center flex-col space-y-12">
      <div>
        <img
          src={ConnectNow}
          alt="Connect Now"
          className="w-full h-full sm:w-[212px] sm:h-[120px] md:w-[376px] md:h-[220px]"
        />
      </div>
      <div
        className="flex items-center space-x-1 text-blue-respect-500 font-bold text-sm [&:hover]:cursor-pointer"
        onClick={() => {
          HRCardClicked('HR Integration');
          navigate('/hr-wizard');
        }}
      >
        <span>{t('hrwizard.card.cta')}</span>
        <ArrowRightIcon className="h-4 w-4" />
      </div>
    </div>
  );
}

function HRIntegration({
  company,
  reloadCompany,
}: IHRIntegrationProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allowConnect, setAllowConnect] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (company?.isHrIntegrationConnected) {
      setAllowConnect(true);
    }
  }, [company]);

  function getHRIntegrationStage(): HRIntegrationStages {
    if (!company?.isHrIntegrationConnected) {
      return HRIntegrationStages.Connect;
    }

    if (
      company?.isHrIntegrationConnected &&
      !company?.hrIntegrationLastSyncedAt
    ) {
      return HRIntegrationStages.Syncing;
    }

    return HRIntegrationStages.Connected;
  }

  function isConnected(): boolean {
    return company?.isHrIntegrationConnected || false;
  }
  return (
    <section className="info-section">
      <div className="flex flex-col space-y-8">
        <div className="flex justify-between flex-wrap sm:flex-nowrap">
          <div className="flex flex-col space-y-8 mb-4">
            <div className="flex flex-col">
              <div className="flex space-x-2">
                <h2 className="capitalize heading-5">
                  {t('account_page.integration.title')}
                </h2>
                <Badge>New</Badge>
              </div>
              <div>
                <span className="text-base text-normal">
                  {t('account_page.integration.subheading')}
                </span>
                {!isConnected() && (
                  <div className='w-full sm:hidden'>
                    <FindOutMore />
                  </div>
                )}
              </div>
            </div>
            <div className="info-section__row sm:max-w-[460px] mt-4">
              <HRIntegrationCard
                providerName={company?.hrIntegrationName}
                integrationStage={getHRIntegrationStage()}
                onConnectionSuccess={reloadCompany}
                lastSyncedAt={company?.hrIntegrationLastSyncedAt}
                allowConnect={allowConnect}
              />
            </div>
            {!isConnected() && (
              <div className="flex flex-row sm:w-[575px] items-start">
                <div className="flex items-center space-x-4 mt-1">
                  <Checkbox
                    checked={allowConnect}
                    onChange={() => setAllowConnect(!allowConnect)}
                  />
                </div>

                <div className="text-base ml-8 relative">
                  <div className={`${isExpanded ? '' : 'line-clamp-2'} relative`}>
                    {company?.termsAgreement.hris ? t('hrwizard.legal_text') : t('marketing.legal_text_full')}
                    {' '}
                    <a href={t('legal_text.cta.url')} className="text-blue-respect-500 underline">
                      {t('legal_text.cta.title')}
                    </a>
                    {isExpanded && (
                      <span className="inline">
                        <button
                          onClick={() => setIsExpanded(!isExpanded)}
                          className="underline pl-1"
                        >
                          {t('action.show_less')}
                        </button>
                      </span>
                    )}
                  </div>
                  {!isExpanded && (
                    <span className="absolute bottom-0 right-0 bg-grey-light-500 pl-2">
                      ...
                      <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="underline ml-1"
                      >
                        {t('action.show_more')}
                      </button>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          {!isConnected() && (
            <div className='w-full hidden sm:inline-block'>
              <FindOutMore />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default HRIntegration;
