import { tv } from 'tailwind-variants';


export const divInput = tv({
  base: 'relative flex flex-grow items-stretch focus-within:z-10 border-0 rounded-md py-1.5 pl-10 ring-1 ring-inset ring-grey-dark-300 cursor-text',
  variants: {
    focus: {
      true: 'ring-2 ring-inset ring-blue-respect-600',
    },
  },
});

export const inputIcon = tv({
  base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
});

export const input = tv({
  base: 'overflow-hidden border-0 placeholder:text-grey-dark-400 text-grey-dark-900 sm:text-sm sm:leading-6 focus:border-0 focus:ring-0 focus:z-10 w-1/3',
});