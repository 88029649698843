import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import Form, { FormMethod } from 'components/atoms/Form';
import FormField from 'components/molecules/FormField';
import Separator from 'components/atoms/Separator';
import Button from 'components/atoms/Tailwind/Button';

import { EMAIL_REGEX } from 'utils/constants';
import { requestPassword } from 'services/authService';
import { DataLayerEvents, EVENT_GROUP_NAME, pushDataLayerEvent } from 'utils/dataLayer';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  passwordResetView: {
    event_group: EVENT_GROUP_NAME,
    event_name: 'password_reset_viewed',
  },
  passwordResetInitiated: {
    event_group: EVENT_GROUP_NAME,
    event_name: 'password_reset_initiated',
  },
};

interface FormData {
  email: string;
}

const ForgottenPassword = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => pushDataLayerEvent(DATA_LAYER_EVENTS.passwordResetView), []);

  const {
    register,
    formState,
    errors,
    handleSubmit,
  } = useForm<FormData>({ mode: 'all' });

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    setFormError('');
    const cloneData = Object.assign({}, data);
    cloneData.email = cloneData.email.toLowerCase();
    const response = await requestPassword(cloneData);

    setIsLoading(false);

    if (response && response.code !== 202) {
      setFormError(response.message);
      return;
    }

    pushDataLayerEvent(DATA_LAYER_EVENTS.passwordResetInitiated);
    navigate('/forgot-password/email');
  };

  return (
    <SingleColumnLayout>
      <SimpleSection title={t('forgotten_password.title')}>
        <p>{t('forgotten_password.description')}</p>
        <Form method={FormMethod.POST} onSubmit={handleSubmit(onSubmit)} error={formError}>
          <FormField
            name='email'
            type='email'
            placeholder={t('forms.email.placeholder')}
            refs={register({ required: true, pattern: EMAIL_REGEX })}
            error={errors.email}
          />
          <Separator offset={24} />
          <Button 
            text={t('forgotten_password.submit_button')} fullWidth 
            disabled={!formState.isValid} 
            isLoading={isLoading} 
            type='submit'
          />
        </Form>
      </SimpleSection>
      <strong className='single-column-layout__link'>
        <Link to='/login'>{t('forgotten_password.login_link')}</Link>
      </strong>
    </SingleColumnLayout>
  );
};

export default ForgottenPassword;
