import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'assets/images/logo-onefit-usc.svg';

import './SingleColumnLayout.scss';
import LanguageSelector from 'components/molecules/LanguageSelector';

interface Props {
  children: ReactNode,
  className?: string;
}
const SingleColumnLayout = ({ children, className = '' }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={`single-column-layout ${className}`}>
      <header className="single-column-layout__header">
        <Logo className="single-column-layout__logo" />
      </header>
      <main className="single-column-layout__main">
        {children}
      </main>
      <footer className="single-column-layout__footer">
        <div className="single-column-layout__footer-menu">
          <div>Urban Sports Club</div>
          <div className="single-column-layout__footer-menu-li">{t('footer.second_link.text')}</div>
          <div>
            <a className="single-column-layout__footer-menu-link"
               href={t('footer.contact_link.href')}
               target="_blank" rel="noreferrer">{t('footer.contact_link.text')}</a>
          </div>
        </div>
        <div className="single-column-layout__footer-menu">
          <div>OneFit</div>
          <div className="single-column-layout__footer-menu-li">Apollolaan 151, 1077 AR Amsterdam</div>
          <div><a className="single-column-layout__footer-menu-link"
                 href='mailto:activation@urbansportsclub.com'
                 target="_blank" rel="noreferrer">{t('footer.contact_link.text')}</a></div>
        </div>
        <div className="single-column-layout__footer-menu">
          <div>
            <LanguageSelector title={t('menu.language_modal.choose_language')} />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SingleColumnLayout;
