import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';

import { Text } from '@urbansportsclub/components/Text';
import { Button } from '@urbansportsclub/components/Button';
import { useCallback, useState } from 'react';

import * as styles from './MarketingBox.variants';

export default function MarketingBox({
  heading,
  body,
  removeAdditionalSpace = false,
  fixedHeight = false,
}: {
  heading: string;
  body: string;
  removeAdditionalSpace?: boolean;
  fixedHeight?: boolean;
}) {
  
  const [copied, setCopied] = useState(false);

  const onCopyClickHandlerHtml = useCallback((text: string): void => {
    let copyText: string | undefined = text
      .replaceAll(/<br \/><br \/>/g, '\n')
      .replaceAll(/<br \/>/g, '\n')
      .replace(/<ul(.*)>/g, '\n')
      .replace(/<li>/g, '• ')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/ul>/g, '\n')
      .replace(/\n{2,}/g, '\n');

    if (removeAdditionalSpace) {
      copyText = copyText.replace(/\s{6,7}/g, '\n');
    }

    navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([copyText || ''], { type: 'text/plain' }),
      }),
    ]);
    setCopied(true);
    toast('Message has been copied to clipboard', {
      autoClose: 3000,
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [removeAdditionalSpace]);


  function getCopyText() {
    return copied ? 'Copied' : 'Copy';
  }
  function getCopyButton(fullwidth = false, hideOnMobile = true) {
    return <Button
              variant="secondary"
              onClick={() => onCopyClickHandlerHtml(body)}
              small={!fullwidth}
              className={styles.button({ copied: copied, hideOnMobile })}
              fullWidth={fullwidth}
            >
              <DocumentDuplicateIcon className="w-4 h-4" />
             {getCopyText()}{fullwidth && ' Message'}
            </Button>;
  }

  function getClassNamesForTextBox() {
    let classes = 'flex items-center bg-grey-light-500 border border-grey-dark-500 rounded-md text-lg text-wrap min-w-[244px] h-full py-3 px-4 md:min-w-[460px] lg:min-w-[502px] cursor-pointer';

    if (fixedHeight) {
      classes += 'min-h-10 max-h-10 ';
    }

    return classes;
  }
  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center w-full">
            <Text as="span" weight="semibold" intent="sm" className="text-base tracking-[0.5px]">
              {heading}
            </Text>
           {getCopyButton()}
          </div>
          <div>
            <div
              className={getClassNamesForTextBox()}
              onClick={() => onCopyClickHandlerHtml(body)}
            >
              <Text
                as="p"
                intent="md"
                className="text-base text-black-midnight-light-500 break-all sm:break-keep sm:max-w-none text-wrap sm:max-w-none"
              >
                <>
                  {parse(
                    sanitizeHtml(body, {
                      allowedClasses: {
                        ul: ['list-disc', 'ml-8'],
                      },
                    }),
                  )}
                </>
              </Text>
            </div>
          </div>
          <div className='sm:hidden'>
            {getCopyButton(true, false)}
          </div>
        </div>
      </div>
    </>
  );
}
