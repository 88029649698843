import './Input.scss';

export interface Props {
  name: string,
  type: string,
  value?: string,
  placeholder?: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
  refs?: React.Ref<HTMLInputElement>,
  Icon?: React.FC
}

const Input = ({
  name,
  type,
  value,
  placeholder = '',
  onChange,
  onKeyPress,
  refs,
  Icon,
}: Props): JSX.Element => (
  <div className="input__wrapper">
    {Icon && <span className="input__icon"><Icon /></span>}
    <input
      className={`input ${Icon ? ' input--with-icon' : ''}`}
      ref={refs}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  </div>
);

export default Input;
