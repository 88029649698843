import { ReactNode } from 'react';

interface Props {
  htmlFor: string;
  text?: string;
  children: ReactNode;
  className?: string;
}

const Label = ({
  htmlFor,
  text = '',
  children,
  className = '',
}: Props): JSX.Element => (
  <label htmlFor={htmlFor} className={className}>
    {children}
    {text}
  </label>
);

export default Label;
