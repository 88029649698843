import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  ChangeEvent,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import { fetchCompany } from 'services/companyService';
import EmployeesList from 'components/molecules/EmployeesList';
import EmployeeDetails from 'components/organisms/EmployeeDetails';
import {
  EMPLOYEES_TABS,
  ACTIVE_TAB,
  PENDING_APPROVAL_TAB,
  PENDING_CANCELLATION_TAB,
  PAGINATION_ITEMS_PER_LIST_VALUES,
} from 'utils/constants';
import { EmployeeState } from 'models/employee';
import {
  GET_EMPLOYEES,
  EmployeesData,
} from 'utils/graphql/queries/employee';
import { useApproveMembership, useRejectMembership, useGetMembership, useBulkApproveMembership, useBulkCancelMembership } from 'services/membershipService';
import useDebounce from 'utils/hooks/useDebounce';
import { ButtonLayerEvent, DataLayerEvents, EVENT_GROUP_NAME, pushDataLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { ITab, ITabsState } from 'models/generic';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { CompanyContext } from 'contexts/CompanyContext';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  membershipDeclined: { event_group: EVENT_GROUP_NAME, event_name: 'membership_declined' },
  membershipApproved: { event_group: EVENT_GROUP_NAME, event_name: 'membership_approved' },
  membershipBulkApproved: { event_group: EVENT_GROUP_NAME, event_name: 'membership_bulk_approved' },
  membershipBulkCancellation: { event_group: EVENT_GROUP_NAME, event_name: 'membership_bulk_cancelled' },
};

const membershipBulkApprovedButtonClickedEvent: ButtonLayerEvent = {
  item: 'Bulk Approved',
  details: 'Bulk Approve Selected',
  origin: 'Home',
};

const membershipBulkCancellationButtonClickedEvent: ButtonLayerEvent = {
  item: 'Bulk Cancelled',
  details: 'Bulk Cancellation',
  origin: 'Home',
};

const Home = (): JSX.Element => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [employeesTabs, setEmployeesTabs] = useState<ITabsState>();
  const [activeTab, setActiveTab] = useState<ITab>(EMPLOYEES_TABS.tabs[EMPLOYEES_TABS.activeIndex]);
  const [forcePage, setForcePage] = useState<number | undefined>();
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(Number(PAGINATION_ITEMS_PER_LIST_VALUES[0]));
  const [sorting, setSorting] = useState<Array<string[]>>();
  const debouncedSearchValue = useDebounce<string>(searchValue, 300);
  const { approveRequest, approveIsLoading } = useApproveMembership();
  const { rejectRequest } = useRejectMembership();
  const { data: detailsData, getMembership, getMembershipLoading } = useGetMembership();
  const { onBulkApproveRequest, isLoading:bulkApproveLoading } = useBulkApproveMembership();
  const [badges, setBadges] = useState<string[]>([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const { onBulkCancelRequest, isLoading:bulkCancelLoading } = useBulkCancelMembership();
  const [isApprovalEnabled, setIsApprovalEnabled] = useState(false);
  const { company } = useContext(CompanyContext);

  useEffect(() => {}, [employeesTabs]);

  const buildQueryVariables = useCallback(() => {
    const order = sorting?.map(([name, value]) => ({ [name]: value }));

    const variables = {
      membershipState: activeTab.status,
      companyId: company ? company.id : null,
      search: debouncedSearchValue || null,
      emails: badges.length > 0 ? badges.join(',') : null,
      page: activePage,
      itemsPerPage,
      order,
    };

    return variables;
  }, [company, activePage, activeTab, debouncedSearchValue, itemsPerPage, sorting, badges]);

  // TODO: Handle error state
  const {
    data,
    loading,
    refetch,
    error,
  } = useQuery<EmployeesData>(
    GET_EMPLOYEES,
    {
      variables: buildQueryVariables(),
    },
  );


  useEffect(() => {
    const tabs = [];
    tabs.push(ACTIVE_TAB);
    if (company?.featureFlags.isApprovalEnabled) {
      tabs.push(PENDING_APPROVAL_TAB);
      setIsApprovalEnabled(true);
    }
    tabs.push(PENDING_CANCELLATION_TAB);
    setEmployeesTabs({
      tabs: tabs,
      activeIndex: 0,
    });
    void refetch();
  }, [company]);

  const onMembershipDetails = (membershipId: string) => {
    getMembership(membershipId);
    setIsModalOpen(true);
  };


  const onEmployeeApprove = async (membershipId: string) => {
    await approveRequest(membershipId);
    pushDataLayerEvent(DATA_LAYER_EVENTS.membershipApproved);

    toast(t('employees_page.details.approve_toast'));
    setIsModalOpen(false);
    void refetch();
  };

  const onEmployeeDecline = async (id: string) => {
    await rejectRequest(id);

    pushDataLayerEvent(DATA_LAYER_EVENTS.membershipDeclined);

    toast(t('employees_page.details.decline_toast'));
    setIsModalOpen(false);

    void refetch();
  };

  const onEmployeeBulkApprove = async (selectedIds: string[]) => {
    
    await onBulkApproveRequest(company?.id ?? '', selectedIds);
    pushToButtonDataLayer(membershipBulkApprovedButtonClickedEvent);
    toast('Selected employees have been sent for approvals');
    refetch();
  };

  const onEmployeeBulkCancellation = async (selectedIds: string[]) => {
    await onBulkCancelRequest(company?.id ?? '', selectedIds);
    pushToButtonDataLayer(membershipBulkCancellationButtonClickedEvent);
    toast('Selected employees have been sent for cancellation');
    refetch();
  };

  const onSearchHandler = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(value);

    if (value.length < 3) {
      if (!value) setSearchValue('');
      return;
    }

    setForcePage(0);
    setActivePage(1);

    if (
      (value.indexOf(',') > 0 || value.indexOf(' ') > 0) &&
      value.indexOf('@') > 0
    ) {
      badges.push(value.trim().split(',')[0]);
      setBadges(badges);
      setSearchInputValue('');
      setSearchValue('');
    } else if (value) {
      setSearchValue(value);
    } else {
      setSearchValue('');
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData('text');
    const emails = pastedValue.split(',').map((el) => el.trim());
    setBadges([...badges, ...emails]);
  };

  const tabClickHandler = (newActiveTab: ITab) => {
    setForcePage(0);
    setActivePage(1);
    setActiveTab(newActiveTab);
  };

  const onEmailRemove = (value: string) => {
    const updateBadges = badges.filter((el) => el !== value);
    setBadges(updateBadges);
  };

  return employeesTabs ? (
    <>
    <SidebarLayout
      heading={t('employees_page.title')}
      search
      onSearchHandler={onSearchHandler}
      loading={loading}
      className="fixed-sidebar-layout--home"
      tabs={employeesTabs}
      tabClickHandler={tabClickHandler}
      tabTranslationKeyGroup="employees_page.tabs."
      badges={badges}
      onRemove={onEmailRemove}
      searchInputValue={searchInputValue}
      onPaste={onPaste}
    >
      <EmployeeDetails
        detailsData={detailsData}
        detailsLoading={getMembershipLoading}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        statusChangeLoading={approveIsLoading}
        onEmployeeApprove={onEmployeeApprove}
        onEmployeeDecline={onEmployeeDecline}
        updateListHandler={refetch}
      />
      <EmployeesList
        onPrimaryButtonClick={activeTab.generalState === EmployeeState.PENDING_APPROVAL
          ? onEmployeeApprove
          : undefined}
        primaryButtonText={activeTab.generalState === EmployeeState.PENDING_APPROVAL
          ? t('employees_page.list.employee_approve_button')
          : undefined}
        onSecondaryButtonClick={onMembershipDetails}
        secondaryButtonText={t('employees_page.list.employee_details_button')}
        data={data}
        emptyListText={t(`employees_page.list.${activeTab.generalState}_empty`)}
        error={error}
        onPaginationChange={(page, newItemsPerPage) => {
          if (newItemsPerPage) {
            setForcePage(page);
            setItemsPerPage(newItemsPerPage);
          }

          setActivePage(page);
          void refetch({ page, itemsPerPage });
        }}
          forcePage={forcePage}
          activePage={activePage}
          onSortChange={(value) => {
            const formattedValue = value.split(',').map((el) => el.split(' '));
            setSorting(formattedValue);
          }}
          activeTab={activeTab}
          isLoading={loading || bulkApproveLoading || bulkCancelLoading}
          onEmployeeBulkApprove={onEmployeeBulkApprove}
          onEmployeeBulkCancel={onEmployeeBulkCancellation}
          allowBulkCancel={isApprovalEnabled}
          company={company}
        />
      </SidebarLayout>
    </>
  ) : <></>;
};

export default Home;
