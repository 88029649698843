import { Button } from '@urbansportsclub/components/Button';
import { Text } from '@urbansportsclub/components/Text';
import {
  ArrowLeftIcon,
  ClockIcon,
  CheckBadgeIcon,
  UserIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/logo-onefit-usc.svg';
import { useTranslation } from 'react-i18next';
import HRSuccess from 'assets/images/hrsuccess.png';
import { CompanyContext } from 'contexts/CompanyContext';
import { useContext } from 'react';

function ListComponent(props: {
  icon: JSX.Element;
  heading: string;
  description: string;
  bgColor?: string;
}) {
  return (
    <li className="flex space-x-3">
      <span>{props.icon}</span>
      <div className="flex flex-col">
        <Text as="span" weight="bold" intent="md" className="text-base text-black-midnight-light-500">
          {props.heading}
        </Text>
        <Text
          as="span"
          intent="md"
          className="text-black-midnight-light-500 text-base"
        >
          {props.description}
        </Text>
      </div>
    </li>
  );
}

function HRSucces() {
  const navigate = useNavigate();
  const { reloadCompany } = useContext(CompanyContext);

  const { t } = useTranslation();

  function toHome() {
    reloadCompany?.();
    navigate('/home');
  }

  return (
    <div className="flex flex-col items-baseline bg-white scroll-smooth px-4 py-4 sm:py-8 sm:px-8 h-full sm:px-8 sm:h-screen">
      <div className="w-full md:px-8">
        <div className="flex justify-between items-center">
          <Button variant="link" className="bg-transparent" onClick={toHome}>
            <ArrowLeftIcon className="h-5 w-5" />
            Back to Portal
          </Button>
          <div>
            <Logo className="w-32" />
          </div>
        </div>
        <div className="flex w-full justify-end items-baseline">
          <span className="text-base text-black-midnight-500 hidden sm:block">
            Integration - HR Connect Setup
          </span>
        </div>
      </div>
      <div className="flex py-2 bg-white sm:justify-center md:items-center md:mt-4 md:pb-16 md:pr-8 flex-wrap w-full md:flex-nowrap">
        <div className="flex flex-col space-y-4 w-full md:items-start sm:max-w-[360px] lg:max-w-[583px]">
          <img src={HRSuccess} alt="HR Success"/>
        </div>
        <div className="flex flex-col space-y-10 sm:space-y-12 w-full mt-8 sm:mt-0">
          <div className="flex flex-col space-y-4 sm:items-center md:items-start">
            <Text as="h1" intent="4xl" weight="bold" className='sm:text-center md:text-left'>
              {t('hrwizard.success.title')}
            </Text>
            <Text as="span" intent="md" className="text-base">
              {t('hrwizard.success.description')}
            </Text>
            <div>
              <Button onClick={toHome}>Back to Portal</Button>
            </div>
          </div>
          <Text as="span" intent="3xl" weight="bold">
            {t('hrwizard.expect.title')}
          </Text>
          <div className="flex flex-col flex-wrap space-y-10 sm:pb-8 md:pb-0 sm:flex-row sm:flex-nowrap sm:space-x-8 sm:space-y-0">
            <div className='lg:min-w-[372px]'>
              <ul className="flex flex-col space-y-10 text-gray-600 sm:space-y-12">
                <ListComponent
                  heading={t('hrwizard.expect.sync.title')}
                  description={t('hrwizard.expect.sync.description')}
                  icon={
                    <ClockIcon className="h-6 w-6 text-green-freedom-500" />
                  }
                />
                <ListComponent
                  heading={t('hrwizard.expect.employee.title')}
                  description={t('hrwizard.expect.employee.description')}
                  icon={
                    <CheckBadgeIcon className="h-6 w-6 text-purple-community-500" />
                  }
                />
              </ul>
            </div>
            <div className='lg:min-w-[372px]'>
              <ul className="flex flex-col space-y-10 text-gray-600 sm:space-y-12">
                <ListComponent
                  heading={t('hrwizard.expect.management.title')}
                  description={t('hrwizard.expect.management.description')}
                  icon={<UserIcon className="h-6 w-6 text-blue-respect-500" />}
                />
                <ListComponent
                  heading={t('hrwizard.expect.disconnect.title')}
                  description={t('hrwizard.expect.disconnect.description')}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-[#E25061]" />
                  }
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HRSucces;
