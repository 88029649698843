import { ButtonLayerEvent } from 'utils/dataLayer';

// When the user click on stay active button
export const stayActiveEvents: ButtonLayerEvent = {
  item: 'Keep Active Clicked',
  origin: 'Reactivation flow',
};

// This will be fired when company has been marked as active
export const confirmedStayActiveEvents: ButtonLayerEvent = {
  item: 'Keep Active Confirmed',
  origin: 'Reactivation flow',
};

// When the user click on copy url button
export const copyEmployeeSignUpUrlEvents: ButtonLayerEvent = {
  item: 'Copy Signup Link',
  origin: 'Reactivation flow',
};

// when the user click on Cancel Contract Button
export const cancelContractEvents: ButtonLayerEvent = {
  item: 'Cancel Contract Clicked',
  origin: 'Reactivation flow',
};

// Once the user clicked on cancelled button and it is processed
export const cancelledContractEvents: ButtonLayerEvent = {
  item: 'Cancel Contract Confirmed',
  origin: 'Reactivation flow',
};