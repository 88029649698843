import { makeVar, ReactiveVar } from '@apollo/client';

const getCleanValueForStorage = (value: any) => (
  typeof value === 'string' ? value : JSON.stringify(value)
);

const makeVarPersisted = <T>(initialValue: T, storageKey: string) => {
  const rv: ReactiveVar<T> = makeVar<T>(initialValue);

  const init = (newValue?: T): T => {
    if (newValue) {
      try {
        localStorage.setItem(
          storageKey,
          getCleanValueForStorage(newValue),
        );

        return rv(newValue);
      } catch {
        // ignore
      }
    }

    return rv();
  };

  const restore = () => {
    try {
      const previousValue = localStorage.getItem(storageKey);

      if (previousValue) {
        rv(typeof initialValue === 'string' ? previousValue : JSON.parse(previousValue));
      }
    } catch {
      // ignore
    }
  };


  const getLatestItem = () => {
    return JSON.parse(localStorage.getItem(storageKey) || '')  as T;
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  init.onNextChange = rv.onNextChange;

  return { init, restore, getLatestItem };
};

export default makeVarPersisted;
