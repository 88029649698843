export const getFromLocalStorage = (key: string): JSON | string | undefined => {
  try {
    const serializedState: string | null = localStorage.getItem(key);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState) as JSON | string;
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStorage = (key: string, value: any): boolean => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
    return true;
  } catch (err) {
    return false;
  }
};

export const clearFromLocalStorage = (key: string): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};

export const clearLocalStorage = (): boolean => {
  try {
    localStorage.clear();
    return true;
  } catch (err) {
    return false;
  }
};
