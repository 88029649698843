import { CustomError } from './api/error';
import { AuthFlow } from './constants';
import {
  DataLayerEvents,
  EVENT_GROUP_NAME,
  pushDataLayerEvent,
} from './dataLayer';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  registrationFailed: {
    event_group: EVENT_GROUP_NAME,
    event_name: 'registration_failed',
  },
  passwordResetFailed: {
    event_group: EVENT_GROUP_NAME,
    event_name: 'password_reset_failed',
  },
};

export const resetPasswordFailedEvent = (
  response: CustomError,
  flow: string | null,
) => {
  if (flow === AuthFlow.REGISTER) {
    pushDataLayerEvent(DATA_LAYER_EVENTS.registrationFailed, {
      error_message: response.message,
    });
  } else {
    pushDataLayerEvent(DATA_LAYER_EVENTS.passwordResetFailed, {
      error_message: response.message,
    });
  }
};
