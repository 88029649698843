import TopBar from 'components/atoms/TopBar/TopBar';
import OnBoardingBar from '../OnboardingBar/OnboardingBar';
import CopyBar from '../CopyBar/CopyBar';
import chosenCompanyVar, { ChosenCompany } from 'utils/graphql/localState/chosenCompany';

function NotificationBar() {
  const choosenCompany: ChosenCompany | void = chosenCompanyVar.init() ?? chosenCompanyVar.restore();

  function getTopBar() {
    if (process.env.REACT_APP_SHOW_ONBOARDING === 'true' && choosenCompany?.isOnboardingQuestionsEnabled) {
      return <TopBar content={<OnBoardingBar />} />;
    }

    return <></>;
  }
  
  return process.env.REACT_APP_SHOW_TOP_BAR === 'true' ?  getTopBar() : <></>;
}


export default NotificationBar;