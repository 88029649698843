import { tv } from 'tailwind-variants';

export const alertDiv = tv({
  base: 'rounded-md p-4',
  variants: {
    background: {
      blue: 'bg-blue-respect-50',
    },
  },
});

export const alertText = tv({
  base: 'text-base font-medium',
  variants: {
    color: {
      blue: 'text-blue-respect-700',
    },
  },
});