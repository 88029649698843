import { Link } from 'react-router-dom';

import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';

import './Button.scss';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NEGATIVE = 'negative',
}

interface Props {
  text: string;
  submit?: boolean;
  wide?: boolean;
  disabled?: boolean;
  size?: ButtonSize;
  type?: ButtonType
  loading?: boolean;
  to?: string;
  onClick?: React.MouseEventHandler;
}

const Button = ({
  text,
  submit = false,
  wide = false,
  disabled = false,
  size = ButtonSize.MEDIUM,
  type = ButtonType.PRIMARY,
  loading = false,
  to = '',
  onClick,
}: Props): JSX.Element => (
  !to ? (
    <button
      type={submit ? 'submit' : 'button'}
      className={`
        button
        ${type !== ButtonType.PRIMARY ? ` button--${type}` : ''}
        ${wide ? ' button--wide' : ''}
        ${size !== ButtonSize.MEDIUM ? ` button--${size}` : ''}
      `}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading
        ? <LoadingIcon className="rotating" />
        : text}
    </button>
  )
    : (
      <Link
        to={to}
        className={`
        button
        ${type !== ButtonType.PRIMARY ? ` button--${type}` : ''}
        ${wide ? ' button--wide' : ''}
        ${size !== ButtonSize.MEDIUM ? ` button--${size}` : ''}
      `}
      >
        {text}
      </Link>
    )
);

export default Button;
