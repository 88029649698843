import MarketingHub from 'components/pages/MarketingHub/MarketingHub';
import Reactivation, { Confirmation } from 'components/pages/Reactivation';
import { RouterProps } from 'routes';
import {
  CursorArrowRaysIcon,
} from '@heroicons/react/24/outline';

const DevRoutes: RouterProps[] = [
  {
    path: '/reactivation',
    component: Reactivation,
  },
  {
    path: '/reactivation/confirmation',
    component: Confirmation,
  },
  {
    path: '/marketing-hub',
    isPrivate: true,
    isNew: true,
    component: MarketingHub,
    icon: CursorArrowRaysIcon,
    addInMenu: true,
    allowedRoles: ['uscweb'],
    name: 'Marketing Hub',
  },
];

export default DevRoutes;