import { datadogRum } from '@datadog/browser-rum';

const liveHost = 'portal.urbansportsclub.com';

if (
  process.env.REACT_APP_ALLOW_RUM === 'true' &&
  window.location.host === liveHost
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.eu',
    service: 'b2b-portal',
    env: process.env.REACT_APP_ENVIRONMENT,
    sessionSampleRate: process.env.REACT_APP_RUM_SESSION_SAMPLE_RATE
      ? parseInt(process.env.REACT_APP_RUM_SESSION_SAMPLE_RATE)
      : 100,
    sessionReplaySampleRate: process.env.REACT_APP_RUM_SESSION_REPLAY_SAMPLE_RATE
      ? parseInt(process.env.REACT_APP_RUM_SESSION_REPLAY_SAMPLE_RATE)
      : 50,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
