import { ReactComponent as ActivationImage } from 'assets/images/activation.svg';
import Section from '../../molecules/Section/Section';
import Button, { ButtonSize, ButtonVariants } from 'components/atoms/Tailwind/Button';
import Reason from './Reason';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAnswerCompanyActivationQuestion } from 'services/companyService';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import { pushToButtonDataLayer } from 'utils/dataLayer';
import { cancelContractEvents, cancelledContractEvents, stayActiveEvents } from './activation_datalayer_events';

function Reactivation() {
  const [showForm, setShowForm] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [otherReason, setOtherReason] = useState<string>('');
  const [reason, setReason] = useState('');
  const [open, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { loading, hasError, answerCompanyActivationQuestions, employeeSignupUrl } = useAnswerCompanyActivationQuestion();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const questionId = searchParams.get('token');

  

  useEffect(() => {
    const onComplete = () => {
      if (!isSubmitted) return;

      if (hasError) {
        if (hasError.code === 422) {
          toast(t('company_activations.activation.error'));
          setTimeout(() => {
            navigate('/login');
          }, 1000);
        } else { 
          toast('An error occurred');
        }
        setIsSubmitted(false);
      } else if (!reason) {
        navigate('/reactivation/confirmation?company_url=' + employeeSignupUrl.toString());
      } else if (reason) {
        navigate('/login');
      }

    };
    onComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, employeeSignupUrl]);

  function showFormHandler() {
    setShowForm(!showForm);
  }

  function getClassForCancellation() {
    return showForm ? 'w-[159px] mt-4' : 'w-[159px] mt-10';
  }

  function onReasonChange(value: string) {

    if (!['no-value', 'other'].includes(value)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setReason(value);
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsDisabled(false);
    setOtherReason(event.target.value);
  }

  function getTextForExtension() {
    return showForm ? t('company_activations.activation.extension1', {
      fallbackLng: 'en',
    }) : t('company_activations.activation.extension2', 'en');
  }

  function getTextForCancellation() {
    return showForm ? t('company_activations.activation.cancellation1') : t('company_activations.activation.cancellation2');
  }

  function getHeadingForCancellation() {
    return showForm ? t('company_activations.activation.cancellation_heading_1') : t('company_activations.activation.cancellation_heading_2');
  }

  function getStayActiveButtonText() {
    return showForm ? 'Continue Contract' : t('company_activations.activation.cancellationButtonText1');
  }

  function onSecondaryButtonClick() {
    if (!showForm) {
      showFormHandler();
    } else {
      onCancelContract();
    }
  }

  function onContinueContract() {
    setReason('');
    setOtherReason('');
    setIsSubmitted(true);
    answerCompanyActivationQuestions(questionId || '', 'yes', '', '');
    pushToButtonDataLayer(stayActiveEvents);
  }

  function onCancelContract() {
    setOpen(true);
    pushToButtonDataLayer(cancelContractEvents);
  }

  function onCancelContractConfirmed() {
    setIsSubmitted(true);
    answerCompanyActivationQuestions(questionId || '', 'no', reason, otherReason);

    cancelledContractEvents.details = reason;
    pushToButtonDataLayer(cancelledContractEvents);
  }

  return <div className="flex flex-col items-center w-screen">
    <div className='mb-10 w-screen h-[293px] bg-[#E5E7EB47] border-b border-[#C7C9CC]'>
      <ActivationImage className="w-[408px] h-[299.63px] sm:mb-0 sm:w-full mt-24" />
    </div>
    <div className='flex justify-between p-4 h-[234px] mt-28'>
      <div className='flex flex-col w-[408px] h-[234px]'>
        <div>
          <Section
            heading='Stay Active'
            description={getTextForExtension()}
          />
        </div>
        <div className='mt-4'>
          <Button 
            text={getStayActiveButtonText()} 
            fullWidth 
            onClick={onContinueContract} 
            isLoading={loading} 
            autoWidth
            size={ButtonSize.MEDIUM}
          />
        </div>
      </div>
      <div className='border-r border-gray-200 mx-8'>

      </div>
      <div className='flex flex-col w-[408px] h-[234px]'>
        <div>
          <Section
            heading={getHeadingForCancellation()}
            description={getTextForCancellation()}
          />
        </div>
        {showForm && <div>
          <div className='border-b border-gray-200 pt-4'></div>
          <div className='pt-4'>
            <Reason onReasonChange={onReasonChange} onInputChange={onInputChange} inputVal={otherReason} />
          </div>
        </div>}
        <div className={getClassForCancellation()}>
          <Button
            text={t('company_activations.activation.cancellationButtonText2')}
            colors={ButtonVariants.PRIMARY}
            onClick={onSecondaryButtonClick}
            fullWidth
            disabled={isDisabled}
            disbaledColor={ButtonVariants.DISABLEDCOMPONENT}
            isLoading={loading}
            autoWidth
            size={ButtonSize.MEDIUM}
          />
        </div>
      </div>
    </div>
    <Dialog
      title={t('company_activations.activation.dialog.title')}
      description={t('company_activations.activation.dialog.description')}
      open={open}
      onCancel={() => setOpen(false)}
      onConfirm={onCancelContractConfirmed}
      onCancelText={t('company_activations.activation.dialog.secondary_button_text')}
      onConfirmText={t('company_activations.activation.dialog.primary_button_text')}
      loading={loading}
    />
  </div>;
}

export default Reactivation;