import * as styles from './Section.styles';
import { VariantProps } from 'tailwind-variants';

interface ISectionProps extends VariantProps<typeof styles.base> { 
  heading: string; 
  description: string;
  subDescription?: string;
}

function Section({ heading, description, subDescription, center }: ISectionProps) {
  return <div className={styles.base({ center })}>
        <div className={styles.heading({ center })}>
            <span className='font-bold text-3xl font-bold'>
                {heading}
            </span>
        </div>
        <div className={styles.description({ center })}>
            <span className='text-base text-wrap font-normal'>
                {description}
            </span>
        </div>
        { subDescription && <div className={styles.subDescription({ center })}>
            <span className='text-base text-wrap font-bold'>
                {subDescription}
            </span>
        </div>}
    </div>;
}

export default Section;