import { AxiosRequestConfig } from 'axios';
import { getFromLocalStorage } from 'utils/localStorage';

interface RequestConfig extends AxiosRequestConfig {
  headers: Record<string, unknown>
}

export const requestConfig: RequestConfig = {
  url: '',
  method: 'GET', // default
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
  params: {},
  timeout: 330000,
  withCredentials: false,
  responseType: 'json',
  maxContentLength: 50000,
  validateStatus(status) {
    return status >= 200 && status < 300;
  },
  maxRedirects: 5,
};

export const getRequestConfig = (apiConfig?: AxiosRequestConfig): AxiosRequestConfig => {
  let config = { ...requestConfig };

  if (apiConfig) config = { ...config, ...apiConfig };

  const token = (getFromLocalStorage(process.env.REACT_APP_AUTH_TOKEN as string) as string);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

export const getAuthRequestConfig = (): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    baseURL: process.env.REACT_APP_API_BASE,
    timeout: 330000,
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
    maxRedirects: 5,
    responseType: 'json',
  };
  return config;
};