import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import chosenCompanyVar from 'utils/graphql/localState/chosenCompany';
import './Header.scss';
import { useGetCompany } from 'services/companyService';
import axios from 'axios';
import { getFromLocalStorage } from 'utils/localStorage';
import { getCustomError } from 'utils/api/error';
import { pushDataLayerEvent, DataLayerEvents, EVENT_GROUP_NAME } from 'utils/dataLayer';
import Button from 'components/atoms/Tailwind/Button';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import TagField from 'components/molecules/TagsField/TagField';
import { ITab, ITabsState } from 'models/generic';
import NotificationBar from 'components/molecules/NotificationBar/NotificationBar';
import ContactUs from '../ContactUs/ContactUs';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  exportMembership: { event_group: EVENT_GROUP_NAME, event_name: 'membership_list_exported' },
};

interface Props {
  search?: boolean;
  onSearchHandler?: React.ChangeEventHandler<HTMLInputElement>;
  heading: string;
  onToggle: React.MouseEventHandler;
  isMenuOpen: boolean;
  buttonText?: string;
  buttonOnClick?: React.MouseEventHandler;
  tabs?: ITabsState;
  tabTranslationKeyGroup?: string;
  tabClickHandler?: (activeTab: ITab) => void;
  loading?: boolean;
  badges?: string[];
  onRemove?: (value: string) => void;
  searchInputValue?: string;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  showDownloadList?: boolean;
  subHeading?: string;
  showContactUs?: boolean;
}

const Header = ({
  search = false,
  onSearchHandler,
  heading,
  onToggle,
  isMenuOpen,
  buttonText,
  buttonOnClick,
  tabs,
  tabClickHandler,
  tabTranslationKeyGroup,
  loading,
  badges,
  onRemove,
  searchInputValue,
  onPaste,
  showDownloadList = true,
  subHeading,
  showContactUs = false,
}: Props): JSX.Element => {
  const [tabsState, setTabsState] = useState(tabs);
  const { t } = useTranslation();
  const chosenCompany = chosenCompanyVar.init() ?? chosenCompanyVar.restore();
  const { data, getCompany } = useGetCompany();

  useEffect(() => {
    getCompany(chosenCompany ? chosenCompany.uuid : '');
    if (tabsState) {
      const { activeIndex: currentActiveIndex, tabs: currentTabs } = tabsState;

      if (tabClickHandler) {
        tabClickHandler(currentTabs[currentActiveIndex]);
      }
    }
  }, []);

  useEffect(() => { 
    setTabsState(tabs);
  }, [tabs]);

  useEffect(() => {
  }, [badges, searchInputValue]);

  const onTabClick = (index: number) => {
    setTabsState((state) => {
      if (state) return ({ ...state, activeIndex: index });

      return state;
    });

    if (tabClickHandler && tabsState) {
      tabClickHandler(tabsState.tabs[index]);
    }
  };

  const onDownloadExportClick = () => {
    pushDataLayerEvent(DATA_LAYER_EVENTS.exportMembership);
    const companyId = data?.company.id || '';
    const companyName = data?.company.name || '';
    const apiBaseUrl = getFromLocalStorage(process.env.REACT_APP_API_BASE as string) as string;
    const token = getFromLocalStorage(process.env.REACT_APP_AUTH_TOKEN as string) as string;
    axios.get(
      `/employees/export?company.id=${companyId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
        baseURL: apiBaseUrl,
      },
    ).then(resp => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',  companyName + '_urbansportsclub_memberships.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, err => {
      getCustomError(err);
    });
  };

  return (
    <header className="header">
      <NotificationBar />
      <div className="flex flex-col justify-end py-6 px-4 sm:px-10 ">
        <div className="flex flex-col sm:items-center space-y-3 sm:flex-row">
          <div>
            <div>
              <h1 className="header__title text-4xl font-bold">{heading}</h1>
            </div>
            {subHeading && <div>
                  <p className="text-base text-black-midnight-light-500">{subHeading}</p>
              </div>
            }
          </div>
          <div className='flex space-x-2 sm:ml-auto'>
            {
              (buttonText && buttonOnClick)
              && (
                <Button
                  text={buttonText}
                  onClick={buttonOnClick}
                  disabled={loading}
                />
              )
            }
            {showDownloadList && data && data.company && data.company.isDownloadCsvEnabled &&
              <div>
                <Button
                onClick={onDownloadExportClick}
                fullWidth
                text={
                  <>
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>{t('header.download_csv_cta')}</span>
                  </>}
                />
              </div>
            }
            {
              showContactUs && <ContactUs />
            }
           </div>
        </div>
        {
          search && (
            <div className='mt-2'>
              <TagField
                name="search"
                type="text"
                placeholder={t('header.search_placeholder')}
                icon={<MagnifyingGlassIcon className='h-5 w-5 text-gray-400' />}
                onChange={onSearchHandler}
                badges={badges}
                onRemove={onRemove}
                value={searchInputValue}
                onPaste={onPaste}
              />
            </div>
          )
        }

        <ul className="header__tabs overflow-x-auto">
          {
            tabsState?.tabs.map((tab, index) => (
              <li
                key={tab.generalState}
                className={`header__tab${tabsState.activeIndex === index ? ' header__tab--active' : ''}`}
              >
                <button
                  type="button"
                  onClick={() => onTabClick(index)}
                  className="header__tab-button"
                >
                  {t(`${tabTranslationKeyGroup as string}${tab.generalState}`)}
                </button>
              </li>
            ))
          }
        </ul>
      </div>
    </header>
  );
};

export default Header;
