import MarketingBox from './MarketingBox';
import { Accordion } from '@urbansportsclub/components/Accordion';
import { AccordionSummary } from '@urbansportsclub/components/Accordion/Summary';
import { AccordionDetails } from '@urbansportsclub/components/Accordion/Details';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Text } from '@urbansportsclub/components/Text';
import { Divider } from 'components/atoms/Tailwind/Divider/Divider';
import { useState } from 'react';


const welcomEmail = `
  Dear *COMPANY* Team,<br /><br />
Finding a balance to work is particularly important for your physical fitness and mental health - your employer sees it that way too. So that you can exercise flexibly and enjoy wellness, *COMPANY* will now contribute if you become a member of the Urban Sports Club.
<br /><br />
Whether you want to clear your head with swimming, overcome your limits with bouldering, fancy a workout with your team or switch off with wellness - with your membership you will find exactly the activity that suits you.
<br /><br />
<b>Take advantage of the exclusive company discount</b>
<br /><br />
Simply sign up via your company registration page and get active now. 
<br /><br />
*LANDING PAGE URL*"
<br /><br />
Your benefits with Urban Sports Club:<br /><br />
 
 <ul class='list-disc ml-8'>
  <li> Train with over 7000 partners throughout Germany - indoors and outdoors</li>
  <li> More than 50 types of sports and wellness with just one membership</li>
  <li> Be even more flexible with live online and on-demand courses</li>
  <li> Everything in one app: simply find a course and check in via smartphone</li>
  <li> Monthly flexible upgrades and downgrades</li>
</ul>
      <br />
`;
const marketingEmail = `
      Hi Team,<br />
      Did you know that our company offers a Urban Sports Club | Onefit membership for free? If you haven’t signed up yet, now is the perfect time to unlock this amazing benefit and start your wellness journey! <br />
       <br />
      Urban Sports Club | Onefit offers:<br /><br />
      <ul class='list-disc ml-8'>
          <li>Access to thousands of fitness classes, from yoga to HIIT</li>
          <li>Convenient class times and locations</li>
          <li>Virtual workouts you can do from home</li>
          <li>Personalized wellness recommendations</li>
      </ul>
      <br />
      Signing up is quick and easy. Click the link below to get started and take the first step towards a healthier, happier you!
      <br /><br />
      Sign Up Here: http://urbansportsclub.com/yourcompany
      <br /><br />
      Best,<br />
      [Your Name]<br />
      [Your Company] Wellness Team
  `;
const slackText = `
Hey team! 💪 Did you know we get free Urban Sports Club | Onefit memberships? 🎉 <br /><br />
  🏋️‍♀️ Tons of fitness classes<br />
  🧘‍♀️ Yoga, HIIT, and more<br />
  🏠 Virtual workouts too<br />
  📍 Convenient locations<br />
  <br />
  Don't miss out on this awesome perk! Sign up now and start your wellness journey:
  <br /><br />
  Sign Up Here: http://urbansportsclub.com/yourcompany
  <br /><br />
  Let's get moving together! 🏃‍♂️🏃‍♀️`;

export default function ActivationEmail({ defaultExpanded = false } : { defaultExpanded?: boolean }) {
  const [showDivider, setShowDivider] = useState(defaultExpanded);

  return (
    <>
      <Accordion className='bg-white border border-grey-natural-500 rounded-md px-6' defaultExpanded={defaultExpanded} onChange={() => { setShowDivider(!showDivider); }}>
        <AccordionSummary 
         expandIcon={
            <ChevronDownIcon className={'text-grey-dark-500 h-auto w-6 h-6'} />
          }
          className='border-0'
        >
          <div className='flex flex-col space-y-1 w-full'>
            <Text intent='md' as='span' weight='bold' className='text-base'>Activation Email</Text>
            <Text intent='md' as='span' className='text-base text-wrap'>Encourage your employees to start using their Urban Sports Club | Onefit membership benefits.</Text>
          </div>
        </AccordionSummary>
       {showDivider && <div className='-mt-2 mb-2'> <Divider /> </div>}
        <AccordionDetails>
          <div className="flex flex-col pt-4 space-y-4">
            <MarketingBox heading='Email Subject' body='Welcome to Urban Sports Club' removeAdditionalSpace fixedHeight/>
            <MarketingBox heading='Email Body' body={marketingEmail} removeAdditionalSpace/>
            <MarketingBox heading='Slack | Whatsapp | Intranet Communication' body={slackText}/>
          </div>
        </AccordionDetails>
    </Accordion>
    </>
  );
}
