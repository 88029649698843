import { tv } from 'tailwind-variants';

export const button = tv({
  variants: {
    hideOnMobile: {
      true: 'hidden sm:flex',
      false: 'flex sm:hidden',
    },
    copied: {
      true: 'border-[#2EBA88] text-[#2EBA88]',
    },
  },
});
