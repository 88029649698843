import { ButtonLayerEvent, pushToActionEvent, pushToButtonDataLayer } from 'utils/dataLayer';

export const TrackOnInviteUserButtonClickStart = () => {
  const event: ButtonLayerEvent = {
    details: 'User Role',
    item: 'Invite User',
    origin: 'Account',
  };
  pushToButtonDataLayer(event);
};

export const TrackOnInviteUserButtonClickCancelled = () => {
  const event: ButtonLayerEvent = {
    details: 'User Role',
    item: 'User Invitation Cancelled',
    origin: 'Account',
  };
  pushToButtonDataLayer(event);
}; 

export const TrackOnInviteUserButtonClickSuccess = () => {
  pushToActionEvent('user_invitation_succeeded');
};

export const TrackOnInviteUserButtonClickError = () => {
  pushToActionEvent('user_invitation_failed');
};

export const TrackOnRemoveUserButtonClickStart = () => {
  const event: ButtonLayerEvent = {
    details: 'User Role',
    item: 'Remove User',
    origin: 'Account',
  };
  pushToButtonDataLayer(event);
};

export const TrackOnRemoveUserButtonClickCancelled = () => {
  const event: ButtonLayerEvent = {
    details: 'User Role',
    item: 'Remove User Cancelled',
    origin: 'Account',
  };
  pushToButtonDataLayer(event);
};

export const TrackOnRemoveUserButtonClickSucceeded = () => {
  pushToActionEvent('user_removed_succeeded');
};

export const TrackOnRemoveUserButtonClickError = () => {
  pushToActionEvent('user_removed_failed');
};