import React from 'react';
import { Navigate } from 'react-router-dom';

import { isAuthenticated } from 'services/authService';

const LOGIN_URL = '/login';

export default (ComposedComponent: any) => {
  const AuthMiddleware = (props: any): JSX.Element => (
    isAuthenticated()
      ? <ComposedComponent {...props} />
      : <Navigate replace to={LOGIN_URL} />
  );
  return AuthMiddleware;
};
