import './Separator.scss';

interface Props {
  offset?: number
  marginBottom?: number
}

const Separator = ({ offset = 0, marginBottom = 24 }: Props):JSX.Element => (
  <hr
    className="separator"
    style={{
      marginLeft: `-${offset}px`,
      marginRight: `-${offset}px`,
      marginBottom: `${marginBottom}px`,
      width: `calc(100% + ${offset * 2})`,
    }}
  />
);

export default Separator;
