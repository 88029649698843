import { getCustomError, CustomError } from 'utils/api/error';
import { useCallback, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getRequestConfig } from 'utils/api/requestConfig';
import { Report, ReportData } from 'models/report';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';

const downloadDataLayerEvent: ButtonLayerEvent = {
  item: 'Download',
  details: 'Download Report Successfully for Report: ',
  origin: 'Report',
};

export const useGetCompanyReports = () => {
  const [reports, setReports] = useState<Report[]>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<CustomError>({ code: 200, message: 'No error' });

  const getReports = useCallback( async (companyId: string) => {
    setLoading(true);
    return axios
      .get(`company/${companyId}/reports`, getRequestConfig())
      .then((response: AxiosResponse) => {
        const data: ReportData = response.data as ReportData;
        setReports(data['hydra:member']);
        setLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage(getCustomError(error));
        setLoading(false);
      });
  }, []);

  return {
    getReports,
    loading,
    errorMsg: errorMessage,
    hasError,
    reports,
  };
};

export const useDownloadReport = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const downloadReport = async (reportId: string, createdMonthYear:string, companyName:string) => {
    setLoading(true);
    return axios
      .get(`reports/${reportId}`, {
        ...getRequestConfig(),
        responseType: 'blob',
      })
      .then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${companyName}-${createdMonthYear}-report.csv`);
        document.body.appendChild(link);
        link.click();
        
        downloadDataLayerEvent.details += reportId;
        pushToButtonDataLayer(downloadDataLayerEvent);
        setLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setLoading(false);
      });
  };

  return {
    downloadReport,
    loading,
    errorMsg,
    hasError,
  };
};
