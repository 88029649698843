import { AlertProps } from './Alert.type';
import * as styles from './Alert.variants';

export default function Alert({ text, icon, prefix, background, color } : AlertProps ) {
  return (
    <div className={styles.alertDiv({ background })}>
      <div className="flex">
        {icon && <div className="flex-shrink-0">
          {icon}
        </div>}
        <div className="ml-3">
          <p className={styles.alertText({ color })}>{ text }</p>
        </div>
        <div className="ml-auto pl-3">
            {prefix}
        </div>
      </div>
    </div>
  );
}