import { Text } from '@urbansportsclub/components/Text';
import { Label } from '@urbansportsclub/components/Label';
import { Button } from '@urbansportsclub/components/Button';
import InviteTeam from 'assets/images/invite-team.png';
import { CompanyContext } from 'contexts/CompanyContext';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { InviteEmployeeProps } from './InviteEmployee.type';
import * as styles from './InviteEmployee.variants';

const copyButtonClickedEvent: ButtonLayerEvent = {
  item: 'Copy Company Link',
  details: 'Add Employee',
  origin: 'Employee',
};

function InviteEmployee({ className }: InviteEmployeeProps): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();

  const onCopyClickHandler = useCallback((): void => {
    const textarea = document.createElement('textarea');
    const utmCode =
      '?utm_source=portal&utm_medium=link&utm_campaign=GLOBAL-ALL-B2B-PTL-PRS-EMPL_INV';
    const urlWithUtm = (company?.employeeSignUpUrl || '') + utmCode;
    textarea.textContent = urlWithUtm;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();

    pushToButtonDataLayer(copyButtonClickedEvent);

    try {
      navigator.clipboard.writeText(urlWithUtm);
      toast(t('invite_employees.toast_notification'));
    } catch (ex) {
      toast(t('account_page.popup.copy_fail_toast'));
    } finally {
      document.body.removeChild(textarea);
    }
  }, [company?.employeeSignUpUrl, t]);

  return (
    <div className={styles.inviteEmployeeDiv({ className })}>
      <img
        src={InviteTeam}
        alt="Invite Team"
        className="max-w-[300px] sm:max-w-[460px] md:max-w-[500px]"
      />
      <Text as="h2" intent="xl" weight="bold" className="text-center mt-4">
        {t('invite_employees.heading')}
      </Text>
      <Text as="h3" intent="md" className="text-center text-base mt-2">
        {t('invite_employees.subheading')}
      </Text>
      <div className="flex flex-col justify-evenly items-start">
        <Label>{t('invite_employees.invite')}</Label>
        <div className="flex flex-wrap items-center space-y-8 max-w-[304px] sm:max-w-none md:flex-nowrap md:space-x-4 md:space-y-0">
          <div className="flex bg-white border border-grey-dark-500 rounded-md text-lg py-3 px-6 truncate space-x-3 w-full md:min-w-[460px] lg:min-w-[502px] cursor-pointer" onClick={onCopyClickHandler}>
            <Text as='span' intent='lg' className='text-black-midnight-light-500/40'>
              http://
            </Text>
            <Text as='span' intent='lg' className='text-black-midnight-light-500 max-w-[260px] sm:max-w-none truncate sm:max-w-none'>
              {company?.employeeSignUpUrl?.replace(/https:\/\/|http:\/\//i, '')}
            </Text>
          </div>
          <Button
            variant="primary"
            onClick={onCopyClickHandler}
            className="w-full md:w-sm"
          >
            {t('invite_employees.invite_cta')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InviteEmployee;
