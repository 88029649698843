import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { ReactComponent as ConfirmationImage } from 'assets/images/confitmation.svg';
import Section from '../../molecules/Section/Section';
import Button, { ButtonSize } from 'components/atoms/Tailwind/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { pushToButtonDataLayer } from 'utils/dataLayer';
import { copyEmployeeSignUpUrlEvents } from './activation_datalayer_events';

function Confirmation() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const onCopyLink = (): void => {
    const utmCode = '?utm_source=portal&utm_medium=link';
    const textarea = document.createElement('textarea');
    const urlWithUtm = `${String(searchParams.get('company_url'))}${utmCode}`;

    textarea.textContent = urlWithUtm;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();

    try {
      navigator.clipboard.writeText(urlWithUtm);
      pushToButtonDataLayer(copyEmployeeSignUpUrlEvents);
      toast(t('account_page.popup.copy_toast'));
    } catch (ex) {
    } finally {
      document.body.removeChild(textarea);
    }
  };

  return <div className="flex flex-col items-center w-screen h-screen">
    <div className='mb-10 w-screen h-[293px] bg-[#E5E7EB47] border-b border-[#C7C9CC]'>
        <ConfirmationImage className="w-[408px] h-[299.63px] sm:mb-0 sm:w-full mt-20" />
    </div>
    <div className='p-4 h-[234px] mt-28'>
        <div className='flex flex-col items-center h-[234px] w-[522px]'>
            <div>
                <Section 
                    heading={t('company_activations.congratulations.title')}
                    description={t('company_activations.congratulations.description')}
                    subDescription={t('company_activations.congratulations.subdescription')}
                    center
                />
            </div>
            <div className='w-[159px] mt-4'>
                <Button 
                  text={<span className='px-6'>{t('company_activations.congratulations.copy_button_text')}</span>}
                  onClick={onCopyLink}
                  size={ButtonSize.MEDIUM}
                  autoWidth
                />
            </div>
        </div>
    </div>
        <div className='flex items-center w-screen justify-end px-5 md:px-9 h-3/5'>
            <span 
                className='text-sm xl:text-md text-blue-respect-500 cursor-pointer' 
                onClick={() => {
                  navigate('/home');
                }} 
            >
                Go to Portal
            </span>
            <ArrowRightIcon
                className='text-blue-respect-500 h-4 w-4 cursor-pointer'
                aria-hidden='true'
            />
        </div>
  </div>;
}

export default Confirmation;