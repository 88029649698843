import { createContext, useEffect, useState } from 'react';

interface User {
  email: string;
}

export interface IUserContext {
  user: User | undefined;
  setUser: (user: User) => void;
}


export const UserContext = createContext<IUserContext>({
  user: {} as User,
  setUser: () => null,
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    localStorage.getItem('email') && setUser({ email: localStorage.getItem('email') as string });
  }, []);
    
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}