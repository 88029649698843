import { Button } from '@urbansportsclub/components/Button';
import { Text } from '@urbansportsclub/components/Text';
import { Checkbox } from '@urbansportsclub/components/Checkbox';
import { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import { useAcceptTerms } from 'services/companyService';
import * as styles from './TermsAndConditions.variant';
import { ITermsAndConditions } from './TermsAndConditions.types';
import { HRIS, MARKETING } from 'utils/constants';
import { pushToButtonDataLayer } from 'utils/dataLayer';

export default function TermsAndConditions({
  page,
  onClick,
  className,
  isDisabled = false,
  isBackgroundDisabled = false,
  isShrinked = false,
} : ITermsAndConditions) {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { acceptTerms, requestFinished, hasError } = useAcceptTerms();

  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [displayText, setDisplayText] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [btnText, setBtnText] = useState<string>('');
  const [isUrlVisible, setIsUrlVisible] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const checkUncheckTermsAndCondition = (origin:string, checked = false) => {
    pushToButtonDataLayer({
      item: 'Terms and Conditions',
      details: checked ? 'Checked' : 'Unchecked',
      origin,
    });
  };

  const handleButtonClick = () => {
    acceptTerms(company?.id || '', page);
    onClick?.();
  };

  const setTermsData = (isAccepted: boolean, text: string, fullText: string, heading: string, button: string) => {
    setTitle(heading);
    setBtnText(button);
    setDisplayText(isAccepted ? text : fullText);
    setIsUrlVisible(isAccepted ? false : true);
  };

  const checkOverflow = () => {
    const element = textContainerRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  };

  useEffect(() => {
    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [displayText]);

  useEffect(() => {
    const isMarketingTermsAccepted = company?.termsAgreement.marketing;
    const isHrisTermsAccepted = company?.termsAgreement.hris;

    if (isMarketingTermsAccepted === undefined || isHrisTermsAccepted === undefined) return;

    setDisplayText('');
    if (page === MARKETING) {
      setTermsData(isHrisTermsAccepted, t('marketing.legal_text'), t('marketing.legal_text_full'), t('marketing.footer.title'), t('marketing.footer.button'));
    }
    
    if (page === HRIS) {
      setTermsData(
        isMarketingTermsAccepted, t('hrwizard.legal_text'), t('hrwizard.legal_text_full'), t('hrwizard.footer.title'), t('hr_integration.card.secondary_button.connect'));
    }
  }, [company?.termsAgreement.hris, company?.termsAgreement.marketing, page, t]);

  useEffect(() => {
    if (requestFinished && hasError) {
      console.log('error'); // TODO: add better error handling
    }
  }, [hasError, requestFinished]);

  if (!displayText) {
    return null;
  }

  return (
    <>
    { isBackgroundDisabled && <div className='fixed inset-0 w-full h-full bg-black bg-opacity-50 z-1 pointer-events-none' /> }
    <div className={styles.containerDiv({ className, isShrinked: isShrinked })}>
      <div className='flex flex-col space-y-2'>
        <Text as='span' intent="xl" weight="bold">
          {title}
        </Text>
        <div className='flex space-x-2 relative'>
          <Checkbox 
            onChange={() => {
              checkUncheckTermsAndCondition(page, !isChecked);
              setIsChecked(prev => !prev);
            }}
            className='mt-1'
          />
          <div 
            ref={textContainerRef} 
            className={`text-base text-wrap ${isExpanded ? '' : 'line-clamp-2'}`}
            style={{ maxHeight: isExpanded ? 'none' : '3.6em', overflow: 'hidden' }}
          >
            <Text as='span' intent='sm' className='text-base text-wrap'>
              {displayText}
              {' '}
              {isUrlVisible && (
                <a href={t('legal_text.cta.url')} className='text-blue-respect-500 underline'>
                  {t('legal_text.cta.title')}
                </a>
              )}
              {isExpanded && (
                <span className="pl-1">
                  <button onClick={() => setIsExpanded(!isExpanded)} className="underline">
                    {t('action.show_less')}
                  </button>
                </span>
              )}
            </Text>
          </div>
          {isOverflowing && !isExpanded && (
            <span className="absolute bottom-0 right-0 bg-white pl-2">
              {'... '}
              <button onClick={() => setIsExpanded(!isExpanded)} className="underline text-base">
                {t('action.show_more')}
              </button>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-5">
        <Button variant='primary' onClick={handleButtonClick} disabled={isChecked || isDisabled} fullWidth className='text-base'>
          {btnText}
        </Button>
      </div>
    </div>
  </>
  );
}
