import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import client from 'utils/graphql/client';
import { GTM_CONFIG } from 'utils/constants';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'i18n';
import 'styles/styles.scss';
import Language from 'Language';
import 'utils/datadog';
import { CompanyProvider } from 'contexts/CompanyContext';
import { UserProvider } from 'contexts/UserContext';
import { AppProvider } from 'context/AppContext';
import './index.css';
TagManager.initialize(GTM_CONFIG);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <UserProvider>
          <CompanyProvider>
            <AppProvider>
              <Language>
                <App />
              </Language>
            </AppProvider>
          </CompanyProvider>
        </UserProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
