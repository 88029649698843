import { pushToButtonDataLayer } from 'utils/dataLayer';

export const TabClickedEvent = (tabName: string) => {
  pushToButtonDataLayer({
    item: 'Change Tab',
    origin: 'Support',
    details: tabName,
  });
};

export const FaqClickedEvent = (faqName: string) => {
  pushToButtonDataLayer({
    item: 'Open in new tab',
    origin: 'Support',
    details: faqName,
  });
};

export const FaqContactUsEvent = () => {
  pushToButtonDataLayer({
    item: 'Contact Us',
    origin: 'Support',
  });
};

export const FaqContactUsCancelEvent = () => {
  pushToButtonDataLayer({
    item: 'Cancel',
    origin: 'Contact Support Form',
  });
};

export const FaqContactUsSubmitEvent = (reason:string) => {
  pushToButtonDataLayer({
    item: 'Submit',
    origin: 'Contact Support Form',
    details: reason,
  });
};