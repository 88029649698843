import Button, { ButtonVariants } from 'components/atoms/Tailwind/Button';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadReport, useGetCompanyReports } from 'services/reportService';
import { ArrowDownTrayIcon, ArrowTrendingUpIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { formatMonthYear } from 'utils/date';
import { Badge } from '@urbansportsclub/components/Badge';
import Alert from 'components/atoms/Tailwind/Alert/Alert';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { CompanyContext } from 'contexts/CompanyContext';

function Report() {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);
  const { getReports, reports, loading } = useGetCompanyReports();
  const { downloadReport, loading:downloadReportloading } = useDownloadReport();
  const [currentDownloadReport, setCurrentDownloadReport] = useState<string | undefined>(undefined);
  const { company } = useContext(CompanyContext);

  useEffect(() => {
    getReports(company?.id ?? '');
  }, [company, getReports]);


  const reportDownloaded = useCallback((reportId:string, createdMonthYear:string, companyName?:string) => {
    setCurrentDownloadReport(reportId);
    downloadReport(reportId, createdMonthYear, companyName || '');
  }, [downloadReport]);
  
  function getTrendBadge(value?: number) {
    if (!value) return null;

    return <span className='ml-2 mr-2 md:mr-4'> <Badge
      className='flex gap-2'
      variant='secondary'
      >
      <div className='flex items-center gap-x-2'>
        <span>{value}</span>
        <ArrowTrendingUpIcon className='w-4 h-4 text-green-freedom-500' />
      </div>
    </Badge>
            </span>;
  }

  return <SidebarLayout
      heading={t('report_page.title')}
      loading={loading}
      subHeading={t('report_page.subtitle')}
      showDownloadList={false}
    >
      <div className='flex flex-col pt-8'>
        {!hidden && <div className='hidden w-full pb-4 md:block'>
          <Alert
            icon={<InformationCircleIcon className='text-blue-respect-500 w-5 h-5 mt-0.5' aria-hidden="true"/>}
            text={t('report_page.alert_text')}
            background='blue' color='blue'
            prefix={<div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex p-1.5 text-blue-respect-500"
              onClick={() => setHidden(true)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>}
          />
        </div>}
        <div className='w-full'>
            {
          reports && reports.length === 0 && <span className='text-base'>{t('report_page.report_not_found')}</span>
        }
        {

        }
         <ul className="space-y-1.5">
            { reports && reports.length > 0 && reports.map((report, index) => (
              <li key={report.reportId} className="overflow-hidden rounded-md bg-white px-6 py-5 shadow">
                <div className='flex justify-between items-center'>
                    <div className='flex flex-col space-y-2'>
                      <div className='flex items-center space-x-2'>
                        <span className='text-base leading-6 font-semibold'>{ formatMonthYear(new Date(report.createdAt)) }</span>
                        {(report.isLatest || index === 0) && <Badge mode='light'>Latest</Badge>}
                      </div>
                      <div className='flex justify-between items-center divide-x divide-grey-natural-600'>
                        <div className='flex items-baseline min-w-32 md:min-w-56'>
                          <span className='text-sm center font-normal'>{report.activeMemberships || 0} <span className='hidden md:inline-flex'>{t('report_page.active_members')}</span> <span className='md:hidden'>{t('report_page.mobile_active_members')}</span></span>
                          {getTrendBadge(report.newMemberships)}
                        </div>
                        <div> <span className='ml-4 text-sm center font-normal'>{report.pausedMemberships || 0} <span className='hidden md:inline-flex'>{t('report_page.paused_members')}</span><span className='md:hidden'>{t('report_page.mobile_paused_members')}</span></span> </div>
                      </div>
                    </div>
                    <div className='-mr-4 md:mr-0'> <Button
                        text={<div className='flex items-center md:space-x-1'>
                          <span className='hidden md:block mt-0.5'>{t('header.download_csv_cta')}</span>
                          <ArrowDownTrayIcon className='h-6 w-6 md:h-5 md:w-5'/>
                        </div>}
                        onClick={() => reportDownloaded(report.reportId, formatMonthYear(new Date(report.createdAt)), company?.name || '')}
                        isLoading={downloadReportloading && currentDownloadReport === report.reportId}
                        fullWidth
                        colors={ButtonVariants.SECONDARY}
                        withoutBorder
                      /> </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SidebarLayout>;
}

export default Report;
