import { useTranslation } from 'react-i18next';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import Button, { ButtonSize } from 'components/atoms/Button';

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SingleColumnLayout>
      <SimpleSection title={t('not_found.title')} wide>
        <p>{t('not_found.content')}</p>
        <Button text={t('not_found.home_button')} to="/home" size={ButtonSize.SMALL} />
      </SimpleSection>
    </SingleColumnLayout>
  );
};

export default NotFound;
