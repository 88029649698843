import { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { ReactComponent as ChevronTopIcon } from 'assets/icons/chevron_top.svg';

import routes from 'routes';
import AuthMiddleware from 'AuthMiddleware';
import {
  DataLayerEvents,
  pushDataLayerEvent,
  dataLayerPlatform,
  EVENT_GROUP_NAME,
} from 'utils/dataLayer';
import i18n from 'i18n';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  // eslint-disable-next-line
  pageView: { event_group: EVENT_GROUP_NAME, event_name: 'page_viewed' },
};

const App = (): JSX.Element => {
  const location = useLocation();

  useLayoutEffect(() => {
    pushDataLayerEvent(DATA_LAYER_EVENTS.pageView, {
      ...dataLayerPlatform,
      page: {
        ...dataLayerPlatform.page,
        language: i18n.language || undefined,
        environment: process.env.REACT_APP_ENVIRONMENT,
      },
    });
  }, [location]);

  return (
    <>
      <Routes>
        {routes.map(({ path, component: Component, isPrivate = false }) => {
          if (isPrivate) {
            const ComponentWithAuth = AuthMiddleware(Component);
            return (
              <Route
                key={path?.toString()}
                path={path}
                element={<ComponentWithAuth/>}
              />
            );
          }
          return <Route key={path?.toString()} path={path} element={<Component />} />;
        })}
      </Routes>
      { !['onboarding', 'reactivation'].includes(location.pathname.split('/')[1]) && 
        <> 
            <button type="button" className="scroll-top">
              <ChevronTopIcon />
            </button>
        </>
      }
      <ToastContainer 
        position="top-right"
      />
    </>
  );
};

export default App;
