import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { Text } from '@urbansportsclub/components/Text';
import ConnectNow from 'assets/images/connect-now.png';
import Syncing from 'assets/images/sync-view.png';
import Kombo from 'assets/images/kombo.png';
import { useTranslation } from 'react-i18next';

interface IGettingStartedSection {
  name: string;
  description: string;
  href?: string;
  imageRef: string;
  externalLinkText?: string;
  note?: string;
}
const features: IGettingStartedSection[] = [
  {
    name: 'hrwizard.getting_started.step1.title',
    description: 'hrwizard.getting_started.step1.description',
    href: '#',
    imageRef: ConnectNow,
    note: 'hrwizard.getting_started.step1.note',
  },
  {
    name: 'hrwizard.getting_started.step2.title',
    description: 'hrwizard.getting_started.step2.description',
    externalLinkText: 'hrwizard.getting_started.step2.link_text',
    href: 'https://help.kombo.dev/hc/en-us/sections/14349683218961-Connection-Guides',
    imageRef: Kombo,
  },
  {
    name: 'hrwizard.getting_started.step3.title',
    description: 'hrwizard.getting_started.step3.description',
    href: '#',
    imageRef: Syncing,
  },
];

function GettingStarted() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mx-auto max-w-2xl lg:mx-0">
        <Text as="h5" intent="xl" weight="bold">
          {t('hrwizard.getting_started.title')}
        </Text>
        <Text as="p" intent="md" className="text-base">
          {t('hrwizard.getting_started.subtitle')}
        </Text>
      </div>
      <div className="mx-auto mt-20 max-w-2xl lg:max-w-none">
        <dl className="grid max-w-xl grid-cols-1 gap-x-16 gap-y-16 lg:max-w-none lg:grid-cols-3">
          {features.map((feature, index) => (
            <div key={feature.name} className="flex flex-col">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-respect-50 text-blue-respect-500">
                  {index + 1}
                </div>
                {t(feature.name)}
              </dt>
              <dd className="mt-1 flex flex-auto flex-col text-base leading-7 space-y-4 text-gray-600">
                <div className='space-y-12'>
                  <p className="flex-auto">
                    {t(feature.description)}
                    {feature.externalLinkText && (
                      <p className="mt-2">
                        <a
                          href={feature.href}
                          className="font-semibold leading-6 text-blue-respect-500"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(feature.externalLinkText)}{' '}
                          <ArrowRightIcon className="h-4 w-4 inline font-bold" />
                        </a>
                      </p>
                    )}
                  </p>
                  <div className="max-w-[436px] max-h-[248px] ">
                    <img
                      src={feature.imageRef}
                      alt={feature.name}
                      className="w-[432px]"
                    />
                  </div>
                </div>
                {feature.note && <div>
                 <Text as='span' intent='md' className='text-base text-black-midnight-light-500 '>{t(feature.note)}</Text>
                </div>}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
export default GettingStarted;
