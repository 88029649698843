import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Interweave } from 'interweave';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import { DataLayerEvents, EVENT_GROUP_NAME, pushDataLayerEvent } from 'utils/dataLayer';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  passwordResetStarted: { event_group: EVENT_GROUP_NAME, event_name: 'password_reset_started' },
};

const ForgottenPasswordSuccess = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => pushDataLayerEvent(DATA_LAYER_EVENTS.passwordResetStarted), []);

  return (
    <SingleColumnLayout>
      <SimpleSection title={t('forgotten_password_success.title')}>
        <p>{t('forgotten_password_success.first_paragraph')}</p>
        <p>{t('forgotten_password_success.second_paragraph')}</p>
      </SimpleSection>
    </SingleColumnLayout>
  );
};

export default ForgottenPasswordSuccess;
