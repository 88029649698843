import { useContext, useEffect, useState } from 'react';
import { OnBoardingContext } from './Onboarding';
import TextArea from 'components/atoms/Tailwind/TextArea';
import Input from 'components/atoms/Tailwind/Input';
import MultiInput from 'components/atoms/Tailwind/MultiInputs';

function Answer() {
  const { setAnswer, getAnswer, getQuestion } = useContext(OnBoardingContext);
  const [currentValue, setCurrentValue] = useState<string>(getAnswer() ?? '');

  useEffect(() => {
    setCurrentValue(getAnswer() ?? '');
  }, [setCurrentValue, getAnswer]);

  function onChange({ target: { value } }: { target: { value: string } }) {
    setAnswer(value);
    setCurrentValue(value);
  }

  function getInput() {
    if (getQuestion().inputType === 'multiple-inputs') {
      return <MultiInput mainValue={getAnswer()} setMainValue={setAnswer} />;
    } else if (getQuestion().inputType === 'input') {
      return  <Input value={currentValue} onChange={onChange} type='text' />;
    }
    return <TextArea value={currentValue} onChange={onChange} rows={3} />;
  }

  return getInput();
}

export default Answer;
