import { ReactNode } from 'react';

import './SimpleSection.scss';

interface Props {
  children: ReactNode;
  className?: string;
  title?: string | undefined;
  wide?: boolean;
}

const SimpleSection = ({
  children,
  className = '',
  title,
  wide = false,
}: Props): JSX.Element => (
  <div className={`simple-section ${wide ? 'simple-section--wide' : ''} ${className}`}>
    {title && <h1 className="simple-section__heading heading-4">{title}</h1>}
    {children}
  </div>
);

export default SimpleSection;
