import { ButtonHTMLAttributes } from 'react';
import * as styles from './Button.styles';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';

export enum ButtonSize {
  EXTRASMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  FULLWIDTH = 'fullwidth',
}

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARYBlue = 'secondaryBlue',
  WHITE = 'white',
  DISABLEDCOMPONENT = 'disabledComponent',
  DANGER = 'danger',
}

interface ButtonProps {
  disabled?: boolean;
  text: string | React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: () => void;
  colors?: ButtonVariants;
  size?: ButtonSize;
  isLoading?: boolean;
  fullWidth?: boolean;
  disbaledColor?: string;
  autoWidth?: boolean;
  withoutBorder?: boolean;
}

export default function Button({ text, disabled, colors = ButtonVariants.PRIMARY, size = ButtonSize.SMALL, type, onClick, isLoading, fullWidth, disbaledColor = 'disabled', autoWidth, withoutBorder }: ButtonProps) {
  return <button
        className={styles.button({ colors: disabled ? disbaledColor : colors, size, widths: fullWidth ? 'fullWidth' : undefined, autoWidth, withoutBorder })}
        disabled={disabled}
        type={type}
        onClick={onClick}
    >
        {isLoading
          ? <LoadingIcon className="rotating" />
          : text}
    </button>;
}