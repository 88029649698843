import { tv } from 'tailwind-variants';

export const button = tv({
  base: 'rounded-md inline-flex items-center',
  variants: {
    colors: {
      disabled:
        'bg-blue-respect-50 text-md font-semibold text-blue-respect-600',
      primary:
        'bg-blue-respect-500 text-md font-semibold text-white hover:bg-blue-respect-600',
      secondary: 'border-2 border-grey-natural-500 text-md font-semibold text-blue-respect-600',
      secondaryBlue: 'border border-blue-respect-500 bg-white text-md font-semibold text-blue-respect-600',
      disabledComponent: 'bg-white border border-black-midnight-light-200 text-base text-black-midnight-light-200 font-bold',
      disabledComponentSecondaryBlue: 'bg-white border border-[#2E70CE66] text-base text-[#337BE266] font-bold',
      danger: 'bg-red-600 font-semibold text-white shadow-sm hover:bg-red-500',
      white: 'bg-white text-md font-semibold text-blue-respect-500 border-0',
    },
    size: {
      xs: 'py-1 px-[7.6px] text-sm',
      sm: 'px-3 py-2 text-sm',
      md: 'px-[13.6px] py-2.5 text-sm',
      lg: 'px-3 py-2 text-lg',
    },
    widths: {
      xs: 'w-16',
      sm: 'w-24',
      md: 'w-32',
      lg: 'w-40',
      fullWidth: 'flex justify-center w-full',
    },
    autoWidth: {
      true: 'sm:w-auto',
    },
    withoutBorder: {
      true: 'border-0',
    },
  },
});
