import { RouteProps } from 'react-router-dom';

import Login from 'components/pages/Login';
import CompanyChoice from 'components/pages/CompanyChoice';
import ForgottenPassword from 'components/pages/ForgottenPassword';
import ForgottenPasswordSuccess from 'components/pages/ForgottenPasswordSuccess';
import ResetPassword from 'components/pages/ResetPassword';
import PasswordSet from 'components/pages/PasswordSet';
import Home from 'components/pages/Home';
import Account from 'components/pages/Account';
import TermsOfUse from 'components/pages/TermsOfUse';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';
import DevRoutes from 'routes-dev';
import OnBoarding from 'components/pages/Onboarding/Onboarding';
import Report from 'components/pages/Report';
import {
  DocumentDuplicateIcon,
  UsersIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { ForwardRefExoticComponent,  RefAttributes,  SVGProps } from 'react';
import Support from 'components/pages/Support/Support';
import HRWizard from 'components/pages/HRWizard/HRWizard';
import HRSucces from 'components/pages/HRSuccess/HRSuccess';

type IconType = ForwardRefExoticComponent<
Omit<SVGProps<SVGSVGElement>, 'ref'> & {
  title?: string | undefined;
  titleId?: string | undefined;
} & RefAttributes<SVGSVGElement>
>;

export type RouterProps = RouteProps & {
  isPrivate?: boolean;
  component: () => JSX.Element;
  name?: string;
  addInMenu?: boolean;
  icon?: IconType;
  allowedRoles?: string[];
  isNew?: boolean;
  featuredFlag?: string;
};

const routes: RouterProps[] = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgottenPassword,
  },
  {
    path: '/forgot-password/email',
    component: ForgottenPasswordSuccess,
  },
  {
    path: '/forgot-password/new',
    component: ResetPassword,
  },
  {
    path: '/forgot-password/success',
    component: PasswordSet,
  },
  {
    path: '/choose-company',
    component: CompanyChoice,
    isPrivate: true,
  },
  {
    path: '/home',
    component: Home,
    isPrivate: true,
    name: 'menu.employees_link',
    addInMenu: true,
    allowedRoles: ['uscweb', 'onefit'],
    icon: UsersIcon,
  },
  {
    path: '/',
    component: Home,
    isPrivate: true,
  },
  {
    path: '/account',
    component: Account,
    isPrivate: true,
    name: 'menu.account_link',
    addInMenu: true,
    allowedRoles: ['uscweb', 'onefit'],
    icon: Cog8ToothIcon,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUse,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/onboarding',
    component: OnBoarding,
    isPrivate: true,
  },
  {
    path: '/report',
    component: Report,
    isPrivate: true,
    name: 'menu.report_link',
    addInMenu: true,
    isNew: false,
    featuredFlag: 'isReportsEnabled',
    icon: DocumentDuplicateIcon,
  },
  {
    path: '/support',
    isPrivate: true,
    isNew: true,
    icon: QuestionMarkCircleIcon,
    addInMenu: true,
    component: Support,
    allowedRoles: ['uscweb'],
    name: 'Support',
  },
  {
    path: '/hr-wizard',
    isPrivate: true,
    isNew: true,
    addInMenu: false,
    component: HRWizard,
    name: 'Hr Wizard',
  },
  {
    path: '/hr-success',
    isPrivate: true,
    isNew: true,
    addInMenu: false,
    component: HRSucces,
    name: 'Hr Success',
  },
  {
    path: '*',
    component: NotFound,
  },
];

if (['localhost', 'portal-staging', 'b2b-portal'].includes(window.location.hostname.split('.')[0])) {
  routes.push(...DevRoutes);
}

export default routes;
