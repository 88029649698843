import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import 'react-dropdown/style.css';
import { Props as EmployeesListHeaderProps } from 'components/molecules/EmployeesListHeader';

import { EmployeesData } from 'utils/graphql/queries/employee';
import { Employee, EmployeeState, EmployeeStateActions } from 'models/employee';
import { formatDate } from 'utils/date';
import { EMPLOYEES_SORT_BY_VALUES, PAGINATION_ITEMS_PER_LIST_VALUES } from 'utils/constants';

import './EmployeesList.scss';
import Table from '../Table';
import { ColumnTypes, TableColumn, Variants } from '../Table/Table';
import Button, { ButtonSize, ButtonVariants } from 'components/atoms/Tailwind/Button';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { useEffect, useState } from 'react';
import Popup from '../Popup';
import { ITab } from 'models/generic';
import { ArrowPathIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { capitalizeFirstLetter } from 'utils/helpers';
import { Company } from 'models/company';
import InviteEmployee from '../InviteEmployee/InviteEmployee';

export interface Props extends EmployeesListHeaderProps {
  onPrimaryButtonClick?: (id: string) => unknown
  primaryButtonText?: string;
  onSecondaryButtonClick?: (id: string) => unknown;
  secondaryButtonText?: string;
  emptyListText: string;
  data: EmployeesData | undefined;
  onPaginationChange: (page: number, itemsPerPage?: number) => void;
  forcePage: number | undefined;
  activePage: number;
  error?: ApolloError;
  activeTab: ITab;
  isLoading?: boolean;
  onEmployeeBulkApprove?: (ids: string[]) => void;
  onEmployeeBulkCancel?: (ids: string[]) => void;
  allowBulkCancel?: boolean;
  company?: Company;
}

const onBulkButtonClickedEvent: ButtonLayerEvent = {
  item: 'Allow Bulk Approve',
  details: 'Bulk Approve Selection',
  origin: 'EmployeesList',
};

const onBulkApprovedButtonClickedEvent: ButtonLayerEvent = {
  item: 'Bulk Approve',
  details: 'Bulk Approve Selected',
  origin: 'EmployeesList',
};

const statesForBulkProcessWithoutApproval = [EmployeeState.PENDING_APPROVAL];
const statesForBulkProcessWithApproval = [EmployeeState.ACTIVE, EmployeeState.PENDING_APPROVAL];

const EmployeesList = ({
  onPrimaryButtonClick,
  primaryButtonText,
  onSecondaryButtonClick,
  secondaryButtonText,
  emptyListText,
  data,
  onPaginationChange,
  activePage,
  error,
  onSortChange,
  activeTab,
  onEmployeeBulkApprove,
  onEmployeeBulkCancel,
  isLoading,
  allowBulkCancel,
  company,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { collection, paginationInfo } = data?.employees || {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCancelledEmployeeIds, setSelectedCancelledEmployeeIds] = useState<string[]>([]);
  const statesForBulkProcess = allowBulkCancel ? statesForBulkProcessWithApproval : statesForBulkProcessWithoutApproval;
  const [selectedRows, setSelectedRows] = useState([] as string[]);

  useEffect(() => {
  }, [company]);
  
  if (error?.message?.length) {
    return <div className="employees-list">{t('employees_page.list.error_message')}</div>;
  }

  if (!collection?.length) {
    return activeTab.generalState === EmployeeState.ACTIVE ? (
      <InviteEmployee className='mt-8'/>
    ) : (
      <div className="employees-list">{emptyListText}</div>
    );
  }

  function onBulkApprove(selectedIds: string[]) {
    pushToButtonDataLayer(onBulkApprovedButtonClickedEvent);
    onEmployeeBulkApprove && onEmployeeBulkApprove(selectedIds);
    setSelectedRows([]);
  }

  function onBulkCancel(selectedIds: string[]) {
    setSelectedCancelledEmployeeIds(selectedIds);
    setIsDialogOpen(true);
  }

  function onBulkCancelConfirm() {
    onEmployeeBulkCancel && onEmployeeBulkCancel(selectedCancelledEmployeeIds);
    setSelectedRows([]);
    setIsDialogOpen(false);
  }

  const columns: TableColumn[] = [
    { name: 'Name', key: 'name',  render: (row:object) => {
      const employee = row as Employee;
      return (
        <span className='mx-4'>
          {`${employee.firstName} ${employee.lastName}`}
          {
            EmployeeState.PAUSED === employee.membershipState
              ? (
                <>
                  &nbsp;-&nbsp;
                  <strong className="red">{t('employees_page.list.employee_paused')}</strong>
                </>
              )
              : ''
          }
        </span>
      );
    } },
    { name: 'Start Date', key: 'startDate', render: (row) => {
      const employee = row as Employee;
      return (
                <span className="sub">{formatDate(new Date(employee.membershipStartDate))}</span>
      );
    } },
    { name: 'Membership Plan', key: 'membershipPlanTypeName', render: (row) => {
      const employee = row as Employee;
      return (
                <span className='ml-3'>{employee.membershipPlanTypeName}</span>
      );
    } },
  ];

  function addCheckBox() {
    columns.unshift({ name: 'ID', key: 'membershipId', type: ColumnTypes.CHECKBOX });
  }

  function getHRIntegrationsName() {
    if (company) {
      return capitalizeFirstLetter(company.hrIntegrationName || '');
    }
    return '';
  }

  function addSyncColumn() {
    if (!company?.isHrIntegrationConnected) return;

    columns.push({ name: getHRIntegrationsName(), key: 'isSynced', render: (row) => {
      const employee = row as Employee;
      return <div className='flex gap-x-2 items-center'>
        {employee.isSynced ? <ShieldCheckIcon className='w-5 h-5 text-[#2EBA88]'/> : <ArrowPathIcon className='w-5 h-5 text-blue-respect-500'/>}
          <span className="subText">{employee.isSynced ? 
            t('employees_page.list.employee_details.hr_integration_synced') : 
            t('employees_page.list.employee_details.hr_integration_syncing')}
          </span>
      </div>;
      
    } });
  }

  function buildColumns(): TableColumn[] {
    if (activeTab.generalState === EmployeeState.PENDING_APPROVAL) {
      addCheckBox();
      columns.push({ name: primaryButtonText ?? secondaryButtonText ?? '', 
        key: 'actions', 
        type: ColumnTypes.BUTTON, 
        variant: Variants.SECONDARY,
        onClick: (row) => {
          const employee = row as Employee;
          if (
            employee.membershipStateActions.includes(EmployeeStateActions.APPROVE)
                  && onPrimaryButtonClick
          ) {
            onPrimaryButtonClick(employee.membershipId);
          }
          if (onSecondaryButtonClick) onSecondaryButtonClick(employee.membershipId);
        },
        render: (row) => {
          const employee = row as Employee;
          return <div className='flex items-end'>
            <div className='flex-grow-1 mx-4'>
              <Button size={ButtonSize.SMALL} colors={ButtonVariants.PRIMARY} onClick={() => {
                if (
                  employee.membershipStateActions.includes(EmployeeStateActions.APPROVE)
                  && onPrimaryButtonClick
                ) {
                  onPrimaryButtonClick(employee.membershipId);
                }
              }} text={primaryButtonText ?? ''} />
            </div>
            <div className='flex-grow-1'>
              <Button size={ButtonSize.SMALL} colors={ButtonVariants.SECONDARY} onClick={() => {
                if (onSecondaryButtonClick) onSecondaryButtonClick(employee.membershipId);
              }} text={secondaryButtonText ?? ''} /> 
            </div>
          </div>;
        }, 
      });
    } else {
      
      if (activeTab.generalState === EmployeeState.ACTIVE) {
        addCheckBox();
        addSyncColumn();
      }

      columns.push({ name: secondaryButtonText ?? '', 
        key: 'actions', 
        type: ColumnTypes.BUTTON, 
        variant: Variants.SECONDARY,
        onClick: (row) => {
          const employee = row as Employee;
          if (
            employee.membershipStateActions.includes(EmployeeStateActions.APPROVE)
                  && onPrimaryButtonClick
          ) {
            onPrimaryButtonClick(employee.membershipId);
          }
          if (onSecondaryButtonClick) onSecondaryButtonClick(employee.membershipId);
        }, 
      });
    }

    return columns;
  }

  function onAllowBulkButtonClick() {
    pushToButtonDataLayer(onBulkButtonClickedEvent);
  }

  return (
    <>
      <Popup
        heading={t('employees_page.dialog.cancellation_heading')}
        isOpen={isDialogOpen}
      >
        {t('employees_page.dialog.cancellation_description')}
        <div className='popup__buttons'>
          <Button
            onClick={() => setIsDialogOpen(false)}
            text={t('employees_page.dialog.cancellation_secondary_button')}
            colors={ButtonVariants.SECONDARY}
            size={ButtonSize.SMALL}
            disabled={isLoading}
          />
          <Button 
            onClick={onBulkCancelConfirm}
            text={t('employees_page.dialog.cancellation_primary_button')}
            size={ButtonSize.SMALL}
            colors={ButtonVariants.DANGER}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </Popup>
      <Table 
        columns={buildColumns()}
        rows={collection ?? []}
        sortable
        sortyBy={EMPLOYEES_SORT_BY_VALUES}
        onSortChange={onSortChange}
        pagination={{
          pageCount: paginationInfo ? paginationInfo.lastPage : 1,
          page: activePage,
          itemsPerPage: paginationInfo?.itemsPerPage ?? 10,
          totalItems: paginationInfo?.totalCount ?? 0,
          onPageChange: (page) => {
            onPaginationChange(page, paginationInfo?.itemsPerPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          },
          itemsPerPageOptions: PAGINATION_ITEMS_PER_LIST_VALUES,
          onChangeItemsPerPage: (itemsPerPage) => {
            onPaginationChange(1, Number(itemsPerPage)); 
          },
          paginationInfo: paginationInfo && t(
            'generic.pagination_info', {
              firstRecord: paginationInfo.itemsPerPage * activePage
              - (paginationInfo.itemsPerPage - 1),
              lastRecord: activePage === paginationInfo.lastPage
                ? paginationInfo.totalCount
                : paginationInfo.itemsPerPage * activePage,
              totalRecords: paginationInfo.totalCount,
            },
          ),
          currentPage: activePage,
          
          onNextPage() {
            onPaginationChange(activePage + 1);
          },
          onPreviousPage() {
            onPaginationChange(activePage - 1);
          },
        }}
        bulkOptions={{
          allowBulkEdit: statesForBulkProcess.includes(activeTab.generalState as EmployeeState),
          editButtonText: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? 'Approve Selected' : 'Cancel Selected',
          editButtonOnClick: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? onBulkApprove : onBulkCancel,
          allowBulkButtonText: 'Bulk Approve',
          onAllowBulkButtonClick: onAllowBulkButtonClick,
          alwaysVisibleCheckbox: statesForBulkProcess.includes(activeTab.generalState as EmployeeState),
          selectedButtonColor: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? ButtonVariants.PRIMARY : ButtonVariants.DANGER,

        }}
        isLoading={isLoading}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
    </>
  );
};

export default EmployeesList;
