import React, { useLayoutEffect, useRef, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Button, { ButtonSize, ButtonVariants }  from 'components/atoms/Tailwind/Button';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
import * as styles from './Table.styles';

export enum ColumnTypes {
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
}

export enum Variants {
  PRIMARY = 'base',
  SECONDARY = 'secondary',
}

export interface TableColumn {
  name: string;
  render?: (row:object) => JSX.Element;
  key: string;
  type?: ColumnTypes;
  onClick?: (row: object) => void;
  variant?: Variants;
}

interface Sort {
  value: string;
  label: string;
}

interface TableProps {
  columns: TableColumn[];
  rows: object[];
  onRowClick?: (row: object) => void;
  sortable?: boolean;
  onSortChange?: (value:string) => void;
  sortyBy?: Sort[];
  pagination?: {
    page: number;
    itemsPerPage: number;
    totalItems: number;
    nextLabel?: string | React.ReactNode;
    previousLabel?: string | React.ReactNode;
    pageCount: number;
    onPageChange?: (page: number) => void;
    itemsPerPageOptions?: string[];
    onChangeItemsPerPage?: (itemsPerPage: string) => void;
    paginationInfo?: string;
    onNextPage?: () => void;
    onPreviousPage?: () => void;
    currentPage?: number;
  },
  bulkOptions?: {
    allowBulkEdit?: boolean;
    editButton?: boolean;
    editButtonText?: string;
    editButtonOnClick?: (selectedRows:string[]) => void;
    allowBulkButtonText?: string;
    onAllowBulkButtonClick?: () => void;
    alwaysVisibleCheckbox?: boolean;
    selectedButtonColor?: ButtonVariants;
  },
  isLoading?: boolean;
  selectedRows?: string[];
  setSelectedRows?: (selectedRows: string[]) => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Table({ 
  columns, rows, sortyBy, onSortChange, sortable, pagination, bulkOptions, isLoading, selectedRows, setSelectedRows }: TableProps): JSX.Element {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isSelectMultiple, setIsSelectMultiple] = useState(bulkOptions?.alwaysVisibleCheckbox ?? false);

  useLayoutEffect(() => {
    const getSelectedRowsLayout = () => selectedRows ?? [];
    const isIndeterminate = getSelectedRowsLayout().length > 0 && getSelectedRowsLayout().length < rows.length;
    setChecked(getSelectedRowsLayout().length === rows.length);
    setIndeterminate(isIndeterminate);
    // checkbox.current.indeterminate = isIndeterminate;
  }, [selectedRows, rows]);

  function toggleAll() {

    setSelectedRows && setSelectedRows(checked || indeterminate ? [] : rows.map((row) => getDataForSelection(row, columns[0].key)));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function getDataForSelection(row: object, key:string): string {
    return String(row[key]);
  }

  function getSelectedRows() {
    return selectedRows ?? [];
  }

  function mapColumnToRow(column: TableColumn, row: object, index:number): JSX.Element {
    if (column.type === ColumnTypes.CHECKBOX) {
      return isSelectMultiple ? <td key={index} className='relative px-7 sm:w-12 sm:px-6'>
        {getSelectedRows().includes(getDataForSelection(row, column.key)) && (
          <div className='absolute inset-y-0 left-0 w-0.5 bg-blue-respect-600' />
        )}
        <input
          type='checkbox'
          className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-respect-600 focus:ring-blue-respect-600'
          value={getDataForSelection(row, column.key)}
          checked={getSelectedRows().includes(getDataForSelection(row, column.key))}
          onChange={(e) => setSelectedRows && setSelectedRows(
            e.target.checked
              ? [...getSelectedRows(), getDataForSelection(row, column.key)]
              : getSelectedRows().filter((p) => p !== getDataForSelection(row, column.key)),
          )} />
      </td> : <></>;
    } else if (column.type === ColumnTypes.BUTTON) {
      const variant = column.variant === Variants.SECONDARY ? ButtonVariants.SECONDARY : ButtonVariants.PRIMARY;

      return <td key={index} className='whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3'>
       { column.render ? 
         column.render(row) : 
        <Button 
          onClick={() => ( column.onClick ? column.onClick(row) : null )}
          size={ButtonSize.MEDIUM}
          colors={variant}
          text={ column.name }
        /> 
      }
      </td>;
    }

    return <td key={index} className={classNames('whitespace-nowrap py-4 pr-3 text-md font-medium mx-4',
      getSelectedRows().includes(getDataForSelection(row, column.key)) ? 'text-blue-respect-600' : 'text-gray-900',
    )}>
      { column.render ? column.render(row)  : row[column.key] }
    </td>;
  }

  function mapColumn(column:TableColumn, index:number): JSX.Element {
    if (column.type === ColumnTypes.CHECKBOX) {
      return isSelectMultiple ? <th key={index} scope='col' className='relative px-7 sm:w-12 sm:px-6'>
        <input
          type='checkbox'
          className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-respect-600 focus:ring-blue-respect-600'
          ref={checkbox}
          checked={checked}
          onChange={toggleAll}
        />
      </th> : <></>;
    } else if (column.type === ColumnTypes.BUTTON) {
      return <th key={index} scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-3'>
        <span className='sr-only'>{column.name}</span>
        {
          sortable &&
          <select
            id="sort-by"
            name="Sort by"
            className="w-28 mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 -mr-9 sm:text-sm sm:leading-6"
            onChange={(e) => onSortChange ? onSortChange(e.target.value) : null}
          >
            <option value="">Sort By</option>
            {
              sortyBy?.map((sort, indexs) => (
                <option key={indexs} value={sort.value}>{sort.label}</option>
              ))
            }
          </select>
        }
      </th>;
    }

    return <th key={index} scope="col" className="px-3 py-3.5 text-left text-md font-semibold text-gray-900">
      {column.name}
    </th>;

  }

  function onMultiSelectClick() {
    setIsSelectMultiple(!isSelectMultiple);
    bulkOptions?.onAllowBulkButtonClick && bulkOptions.onAllowBulkButtonClick();
  }

  function getPreviousPage() {
    if (pagination?.currentPage === 1) {
      return 1;
    }

    return pagination?.currentPage;
  }

  function getNextPage() {
    return pagination?.currentPage ? pagination.currentPage + 1 : 0;
  }

  function isActive(page:number) {
    return pagination?.currentPage === page;
  }
  return (
    isLoading ? <LoadingIcon className="fixed-sidebar-layout__loading-icon rotating" role="img" /> : <div className='flow-root'>
      <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <div className='relative max-h-lvh sm:max-h-[700px] overflow-y-auto'>
              <table className='min-w-full table-fixed divide-y divide-gray-300'>
                <thead>
                  <tr>
                    {
                      columns.map((column, index) => mapColumn(column, index))
                    }
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {
                    rows.map((row, index) => (
                      <tr key={index} className={getSelectedRows().includes(getDataForSelection(row)) ? 'bg-gray-50' : undefined}>
                        {columns.map((column, columnIndex) => mapColumnToRow(column, row, columnIndex))}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 flex-row">
              <div className="flex flex-1 items-center justify-between">
                <div className=''>
                  <p className="text-sm text-gray-700">
                    {pagination?.paginationInfo}
                  </p>
                </div>
                <div className='flex justify-center w-14'>
                  <nav className="isolate inline-flex rounded-md shadow-sm" aria-label="Pagination">
                      <button
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        onClick={() => pagination?.onPreviousPage ? pagination.onPreviousPage() : null}
                        disabled={pagination?.currentPage === 1}
                      >
                        <span className="sr-only">Previous</span>
                        {pagination?.previousLabel ? pagination.previousLabel : <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
                      </button>
                      <div className='flex w-max-[300px] overflow-x-auto'>
                        {
                            <button
                              aria-current="page"
                              className={styles.pagination({ colors: isActive(getPreviousPage()) ? 'active' : undefined })}
                              onClick={() => pagination?.onPageChange ? pagination.onPageChange(getPreviousPage()) : null}
                            >
                              {getPreviousPage()}
                            </button>
                        }
                        
                        {
                          (pagination?.pageCount && getNextPage() <= pagination?.pageCount) && 
                            <button
                                  aria-current="page"
                                  className={styles.pagination({ colors: isActive(getNextPage()) ? 'active' : undefined })}
                                  onClick={() => pagination?.onPageChange ? pagination.onPageChange(getNextPage()) : null}
                                >
                                  {getNextPage()}
                                </button>
                          }
                      </div>
                      <button
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        onClick={() => pagination?.onNextPage ? pagination.onNextPage() : null}
                        disabled={pagination?.currentPage === pagination?.pageCount}
                      >
                        <span className="sr-only">Next</span>
                        {pagination?.nextLabel ? pagination.nextLabel : <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
                      </button>
                    </nav>
                </div>
                <div className='flex reverse space-x-4 items-baseline justify-start'>
                  {getSelectedRows().length > 0 && <div>
                    <Button
                      text={bulkOptions?.editButtonText ?? 'Bulk edit'}
                      onClick={() => (bulkOptions?.editButtonOnClick ? bulkOptions.editButtonOnClick(getSelectedRows()) : null)}
                      colors={bulkOptions?.selectedButtonColor ? bulkOptions.selectedButtonColor : ButtonVariants.PRIMARY}
                      size={ButtonSize.SMALL}
                      disabled={false}
                      type='button'
                    />
                  </div>}
                  <div className='flex space-x-4 items-baseline justify-end'>
                    {bulkOptions?.allowBulkEdit && !bulkOptions?.alwaysVisibleCheckbox && <div>
                      <Button
                        onClick={onMultiSelectClick}
                        size={ButtonSize.SMALL}
                        colors={ButtonVariants.SECONDARY}
                        text={bulkOptions?.allowBulkButtonText ?? 'Multiselect'}
                      /> 
                    </div>}
                    <div>
                      <select
                        id="items-per-page"
                        name="Items Per Page"
                        className="w-15 mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => pagination?.onChangeItemsPerPage ? pagination?.onChangeItemsPerPage(e.target.value) : null}
                        defaultValue={pagination?.itemsPerPage}
                      >
                        {
                          pagination?.itemsPerPageOptions?.map((itemsPerPage, indexs) => (
                            <option key={indexs} value={itemsPerPage}>{itemsPerPage}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}