import './Radio.scss';

interface Props {
  id: string;
  name: string;
  value: string;
  label: string | React.ReactNode;
  checked?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Radio = ({
  id,
  name,
  value,
  label,
  checked = false,
  onChange,
}: Props): JSX.Element => (
  <label htmlFor={id} className="radio">
    <input id={id} name={name} className="radio__input" type="radio" value={value} onChange={onChange} checked={checked} />
    <span className="radio__styled" />
    <span className="radio__label">{label}</span>
  </label>
);

export default Radio;
