import { AxiosError } from 'axios';
import i18next from 'i18next';
import { ResponseError } from '.';

export interface CustomError {
  code?: number;
  message: string;
}

/**
 * 
 * @param err 
 * @param translationErrorsKeyPrefix string to be used as prefix for the translation key
 * @returns 
 */
export const getCustomError = (err: AxiosError<ResponseError>, translationErrorsKeyPrefix = '' ): CustomError => {
  const error: CustomError = {
    message: i18next.t('generic.unknown_error'),
  };

  if (err.response?.data?.status || err.response?.data?.code) {
    error.code = err.response.data.status || err.response.data.code;
    if (err.response.data.message) error.message = err.response.data.message;
  } else if (err.response?.status === 500 && !err.response?.data?.['hydra:description']) {
    error.code = err.response.status;
    error.message = i18next.t([translationErrorsKeyPrefix + '.unknown', 'generic.unknown_error']);
  } else if (!err.response && err.message) {
    error.message = err.message;
  } else if (err.response?.data && err.response?.data['hydra:description']) {
    error.message = i18next.t([
      translationErrorsKeyPrefix +
        '.' +
        err.response?.data['hydra:description'],
      translationErrorsKeyPrefix + '.unknown',
      'generic.unknown_error',
    ]);
  }

  return error;
};
