import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron_right.svg';

import './Choice.scss';
import { Company } from 'models/company';

export interface ChoiceItem {
  uuid: string,
  id: string,
  text: string,
  data?: any,
  integrationName: string,
  isOnboardingQuestionsEnabled: boolean,
  hrIntegrationName?: string,
  hrIntegrationLastSyncedAt?: string
  isReportsEnabled?: boolean;
  company: Company;
}

interface Props {
  item: ChoiceItem,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
}

const Choice = ({ item, onClick }: Props): JSX.Element => (
  <button className="choice heading-5" onClick={onClick} type="button">
    {item.text}
    <ChevronRightIcon className="choice__icon" />
  </button>
);

export default Choice;
