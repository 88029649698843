import { useTranslation } from 'react-i18next';
import { Interweave } from 'interweave';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';

const TermsOfUse = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SingleColumnLayout className="login-page">
      <SimpleSection title={t('terms_of_use.title')} wide>
        <Interweave content={t('terms_of_use.content')} />
      </SimpleSection>
    </SingleColumnLayout>
  );
};

export default TermsOfUse;
