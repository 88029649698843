import './LanguageSelector.scss';
import { Language } from 'models/generic';
import { DEFAULT_LANGUAGE, LANGUAGES } from 'utils/constants';
import { useEffect, useState } from 'react';
import i18n from 'i18next';
import languageChangeHandler from 'utils/translations';
import { ReactComponent as LanguagesIcon } from 'assets/icons/languages.svg';
import { ReactComponent as LanguagesIconWhite } from 'assets/icons/language-icon-white.svg';

interface Props {
  title: string,
  whiteIcon?: boolean,
}

const LanguageSelector = ({ title, whiteIcon = false }: Props): JSX.Element => {
  const [selected, setSelected] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelected(i18n.language);
  }, []);

  const setLanguage = (lang: string) => {
    setSelected(lang);
    setIsOpen(false);
    languageChangeHandler(lang);
  };

  const getLanguageCodes = (): string[] => {
    return LANGUAGES.map((lang: Language) => lang.code);
  };

  const getLanguageByCode = (code: string): Language => {
    const language = LANGUAGES.find((lang: Language) => lang.code === code);
    return language || DEFAULT_LANGUAGE;
  };

  return (
    <>
      <div className="language-selector">
        <button onClick={() => setIsOpen(!isOpen)} type="button" className="dropdown-toggle menu__language-button heading-6" >
          <div className='flex inline-flex items-center justify-center space-x-2'>
            <div>
              {whiteIcon ? <LanguagesIconWhite className='w-6' /> : <LanguagesIcon />}
            </div>
            <div>
              {title}
            </div>
          </div>
        </button>
        <div className={`dropdown-menu ${isOpen ? '' : 'hidden'}`}>
          <ul>
            {
              getLanguageCodes().map((code) => (
                <li key={code}
                    onClick={() => setLanguage(code)}
                    aria-hidden="true"
                    className={selected === code ? 'selected' : ''}>
                  <a>{ getLanguageByCode(code).name }</a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};

export default LanguageSelector;
