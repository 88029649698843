import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  concat,
} from '@apollo/client';

import { getFromLocalStorage } from 'utils/localStorage';
import cache from './cache';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENTRYPOINT,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getFromLocalStorage(process.env.REACT_APP_AUTH_TOKEN as string) as string}` || null,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
});

export default client;
