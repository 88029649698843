import { tv } from 'tailwind-variants';

export const containerDiv = tv({
  base: 'fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-12 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-16 z-2',
  variants: {
    isShrinked: {
      true: 'lg:ml-72',
    },
  },
});
