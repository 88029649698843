import Input from 'components/atoms/Tailwind/Input';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

interface IReasonProps {
  onReasonChange: (value:string) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputVal?: string;
}

interface IReasonMap {
  [key:string]: string;
}

const Reasons: IReasonMap = {
  'no-value' : 'Please Select a Reason',
  'product-offer' :  t('company_activations.reasons.product-offer'),
  'competition': t('company_activations.reasons.competition'),
  'employee-interest': t('company_activations.reasons.employee-interest'),
  'nearby-partners': t('company_activations.reasons.nearby-partners'),
  'price': t('company_activations.reasons.price'),
  'company-closure': t('company_activations.reasons.company-closure'),
  'other': t('company_activations.reasons.other'), 
};

function Reason({ onReasonChange, onInputChange, inputVal }: IReasonProps) {
  const [value, setValue] = useState('');

  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setValue(event.target.value);
    onReasonChange(event.target.value);
  }

  return (
        <div className='flex flex-col justify-between'>
            <div className='pb-1'>
                <span className='text-lg leading-6 font-bold'>Please select a reason for cancellation.</span>
            </div>
            <div className='pb-4'>
                <select
                    id="location"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-respect-600 sm:text-sm sm:leading-6"
                    defaultValue='no-value'
                    onChange={handleSelectChange}
                >
                    { Object.keys(Reasons).map((key) => 
                        <option key={key} value={key}>{Reasons[key]}</option>,
                    )}
                </select>
            </div>
            {value === 'other' && <div className='pb-4'>
                <Input placeholder='Please specify' onChange={onInputChange} value={inputVal}/>
            </div>}
        </div>
  );
}

export default Reason;