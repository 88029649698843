import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { Tabs } from '@urbansportsclub/components/Tabs';
import { TabsItem } from '@urbansportsclub/components/Tabs/Item';
import ActivationEmail from './ActivationEmail';
import TermsAndConditions from 'components/organisms/TermsAndConditions/TermsAndConditions';
import { MARKETING } from 'utils/constants';

function MarketingHub() {
  return (
    <SidebarLayout heading="Marketing Hub" showDownloadList={false} >
      <div className="flex flex-col pt-2 w-full">
        <div className="flex w-full border-r-0 border-b border-grey-natural-500">
          <Tabs
            defaultOption="Message Template"
            variant="primary"
            mode="light"
            className="border-0 hidden sm:flex"
          >
            <TabsItem value="Message Template">Message Template</TabsItem>
          </Tabs>
          <span className='hidden sm:block relative py-4 px-4 text-base font-normal whitespace-nowrap text-black-midnight-light-500/40'>
            Poster, Flyers, Downloadable (Comming Soon)
          </span>
        </div>
        <div className='mt-8 flex flex-col space-y-4'>
          <ActivationEmail defaultExpanded/>
          <ActivationEmail defaultExpanded={false}/>
          <ActivationEmail defaultExpanded={false}/>
        </div>
      </div>
      <TermsAndConditions page={MARKETING} isBackgroundDisabled isShrinked />
    </SidebarLayout>
  );
}

export default MarketingHub;
