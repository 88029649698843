import { pushToActionEvent, pushToButtonDataLayer } from 'utils/dataLayer';

export const HRConnectNow = (origin = 'Account', details?:string) => {
  pushToButtonDataLayer({
    details: details ?? 'HR Connect Integration',
    item: 'Connect HR Systtem',
    origin,
  });
};

export const HRDisconnectNow = () => {
  pushToButtonDataLayer({
    details: 'HR Disconnect Integration',
    item: 'Disconnect HR Systtem',
    origin: 'Account',
  });
};

export const HRDisconnectCancel = () => {
  pushToButtonDataLayer({
    details: 'HR Disconnect Integration',
    item: 'Disconnect HR Systtem Canceled',
    origin: 'Account',
  });
}; 

export const HRConnectedSuccess = () => {
  pushToActionEvent('hr_integration_succeeded');
};

export const HRConnectionFailed = () => {
  pushToActionEvent('hr_integration_failed');
};

export const HRDisconnectionSuccess = () => {
  pushToActionEvent('hr_integration_disconnected');
};

export const HRDisconnectionFailed = () => {
  pushToActionEvent('hr_integration_disconnection_failed');
};

export const HRCardClicked = (origin = 'Navigation Bar') => {
  pushToButtonDataLayer({
    item: 'Find Out How',
    details: 'HR Integration',
    origin,
  });
};

export const HrTabClicked = (item: string) => {
  pushToButtonDataLayer({
    item,
    origin: 'HR Wizard',
  });
};

export const HRBackToPortal = () => {
  pushToButtonDataLayer({
    item: 'Back to Portal',
    origin: 'HR Wizard',
  });
};