import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import EN from './en/en.json';
import DE from './de/de.json';
import FR from './fr/fr.json';
import IT from './it/it.json';
import PT from './pt/pt.json';
import ES from './es/es.json';
import NL from './nl/nl.json';

const DEBUG_ENABLED = true;

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development' && DEBUG_ENABLED,
    fallbackLng: 'en',
    lng: localStorage.getItem('lng') || process.env.REACT_APP_DEFAULT_LANGUAGE,
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
      fr: {
        translation: FR,
      },
      it: {
        translation: IT,
      },
      pt: {
        translation: PT,
      },
      es: {
        translation: ES,
      },
      nl: {
        translation: NL,
      },
    },
  });

export default i18n;
