import makeVarPersisted from 'utils/graphql/makeVarPersisted';

export interface ChosenCompany {
  uuid: string;
  id: string;
  name: string;
  integrationName: string;
  isOnboardingQuestionsEnabled: boolean;
  hrIntegrationName?: string;
  hrIntegrationLastSyncedAt?: string;
  isReportsEnabled?: boolean;
}

const chosenCompanyVar = makeVarPersisted<ChosenCompany | null>(null, 'chosenCompany');

export default chosenCompanyVar;
