import { Card } from '@urbansportsclub/components/Card';
import { Text } from '@urbansportsclub/components/Text';
import {
  GiftIcon,
} from '@heroicons/react/16/solid';
import {
  ArrowRightIcon,
} from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HRCardClicked } from 'tracking/HrTracking';
function HRWizardCard() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card className='bg-blue-respect-50 rounded-md p-4'>
      <div className="flex flex-col space-y-2">
        <div className='flex space-x-2'>
            <GiftIcon className="h-5 w-5 text-blue-respect-500" />
            <Text as='span' intent='md' className='text-blue-respect-700 text-base font-bold'>{t('hrwizard.card.title')}</Text>
        </div>
        <Text intent="md" as='p' className='ml-7 text-base text-blue-respect-600 tracking-[0.5px]'>
          {t('hrwizard.card.subtitle')}
        </Text>
        <div className='flex items-center space-x-1 text-blue-respect-700 text-base font-bold [&:hover]:cursor-pointer ml-7'
          onClick={() => {
            HRCardClicked();
            navigate('/hr-wizard');
          }}>
            <span>{t('hrwizard.card.cta')}</span>
            <ArrowRightIcon className='h-4 w-4'/>
          </div>
      </div>
    </Card>
  );
}

export default HRWizardCard;
