import { VariantProps } from 'tailwind-variants';
import * as styles from './Tooltip.styles';
import { useState } from 'react';

interface TooltipItemProps extends VariantProps<typeof styles.tooltipDiv> {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
}

const TooltipItem = ({ children, position, tooltip }: TooltipItemProps) => { 
  const [hidden, setHidden] = useState(true);
  
  function toggleHidden() {
    setHidden(!hidden);
  }

  return (
    <div className="group relative" onMouseOver={toggleHidden} onMouseOut={toggleHidden}>
        {children}
        <div className={styles.tooltipDiv({ position, className: hidden ? 'hidden' : '' })}>
            <span className={styles.tooltipSpan({ position })}></span>
            {tooltip}
        </div>
    </div>
  );
};

export default TooltipItem;