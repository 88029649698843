import Button, { ButtonSize, ButtonVariants } from 'components/atoms/Tailwind/Button';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { ReactComponent as Logo } from 'assets/images/logo-onefit-usc.svg';
import { useContext } from 'react';
import { OnBoardingContext } from './Onboarding';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { useTranslation } from 'react-i18next';

const onboardingEvents: ButtonLayerEvent = {
  item: 'OnBoarding Started',
  details: 'On Boarding Started',
  origin: 'OnBoarding',
};

function StartScreen() {
  const { nextQuestion, loading } = useContext(OnBoardingContext);
  const { t } = useTranslation();

  function onLetsDoIt() {
    pushToButtonDataLayer(onboardingEvents);
    nextQuestion();
  }

  return (
    <div className='flex flex-col items-center'>
      <div>
        <Logo className='w-52' />
      </div>
      <div className='w-fit'>
        <span className='font-bold text-2xl sm:text-3xl text-black-midnight-500'>
          {t('onboarding.start_screen.title')}
        </span>
      </div>
      <div className='w-fit sm:w-[408px] pt-2 pb-6 pl-[12px] text-center'>
        <span className='text-base text-wrap'>
          {t('onboarding.start_screen.description')}
        </span>
      </div>
      <div>
        <Button
          size={ButtonSize.MEDIUM}
          text={
            <>
              <span className='text-base leading-5 mr-1'> {t('onboarding.start_screen.cta')}</span>
              <ArrowRightIcon className='h-4 w-4' aria-hidden='true' />
            </>
          }
          onClick={onLetsDoIt}
          isLoading={loading}
          disabled={loading}
          disbaledColor={ButtonVariants.SECONDARYBlue}
          fullWidth
        />
      </div>
    </div>
  );
}

export default StartScreen;
