import { MouseEventHandler, ReactNode } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import './Popup.scss';

export enum PopupSize {
  SMALL = 'small',
  BIG = 'big',
}

interface Props {
  children: ReactNode;
  className?: string;
  onClose?: MouseEventHandler;
  heading: string;
  isOpen: boolean;
  size?: PopupSize;
}

const Popup = ({
  children,
  className = '',
  onClose,
  heading,
  isOpen,
  size = PopupSize.SMALL,
}: Props): JSX.Element | null => {
  if (!isOpen) return null;

  return (
    <div className={`popup-wrapper ${className}`}>
      <div className={`popup popup--${size}`}>
        <div className="popup__header">
          {
            onClose && (
              <button className="popup__close" type="button" onClick={onClose}>
                <CloseIcon />
                Close
                {/* Text for accessibility */}
              </button>
            )
          }
          <h3 className={`heading-${size === PopupSize.SMALL ? '5' : '4'} popup__heading`}>{heading}</h3>
        </div>
        <div className="popup__content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
