import { tv } from 'tailwind-variants';

export const tooltipDiv = tv({
  base: 'absolute rounded bg-black-midnight-light-500 px-[24px] py-[20px] opacity-0 group-hover:opacity-100 w-[287px]',
  variants: {
    position: {
      left: 'right-full top-1/2 z-20 mr-3 -translate-y-1/2',
      right: 'left-full top-1/2 z-20 ml-3 -translate-y-1/2',
      bottom: 'top-full left-1/2 z-20 mt-3 -translate-x-1/2',
      top: 'bottom-full left-1/2 z-20 mb-3 -translate-x-1/2',
    },
  },
});

export const tooltipSpan = tv({
  base: 'absolute rotate-45 rounded-sm bg-black-midnight-light-500',
  variants: {
    position: {
      left: 'right-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2',
      right: 'left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2',
      bottom: 'left-1/2 top-[-3px] -z-10 h-2 w-2 -translate-x-1/2',
      top: 'left-1/2 top-[-3px] -z-10 h-2 w-2 -translate-x-1/2',
    },
  },
});