import { useTranslation } from 'react-i18next';
import { Interweave } from 'interweave';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';

const PrivacyPolicy = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SingleColumnLayout>
      <SimpleSection title={t('privacy_policy.title')} wide>
        <Interweave content={t('privacy_policy.content')} />
      </SimpleSection>
    </SingleColumnLayout>
  );
};

export default PrivacyPolicy;
