import Choice, { ChoiceItem } from 'components/molecules/Choice';

type ChoiceEventHandler = (item: ChoiceItem) => void;

interface Props {
  items: ReadonlyArray<ChoiceItem>,
  onChoose: ChoiceEventHandler,
}

const ChoiceList = ({ items, onChoose }: Props): JSX.Element => {
  const handleClick = (item: ChoiceItem) => onChoose(item);

  return (
    <div>
      {
        items.map((item) => (
          <Choice item={item} onClick={() => handleClick(item)} key={item.id} />
        ))
      }
    </div>
  );
};

export default ChoiceList;
