import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n';
import { useAppContext } from 'context/AppContext';

function Language({ children }: PropsWithChildren) {
  const { search } = useLocation();
  const { setLanguage: setAppLanguage } = useAppContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const lng: string = searchParams.get('lang')
      ? searchParams.get('lang')
      : process.env.REACT_APP_DEFAULT_LANGUAGE;

    setLanguage(lng);
  }, []);

  function setLanguage(lng: string) {
    localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
    setAppLanguage(lng);
  }

  return children;
}

export default Language;
