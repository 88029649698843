import i18n from 'i18n';
import { createContext, useContext, useState } from 'react';

export interface ZendeskSection {
  id: number;
  name: string;
}

interface AppContextProps {
  children: React.ReactNode;
  language: string;
  setLanguage: (language: string) => void;
}

const AppContext = createContext<AppContextProps>({
  children: null,
  language: 'en',
  setLanguage: () => {},
});

export const AppProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [language, setLanguage] = useState(i18n.language);

  function onChangeLanguage(lng: string) {
    setLanguage(lng);
  }

  return (
    <AppContext.Provider value={{ children, language, setLanguage: onChangeLanguage }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextProps => {
  return useContext(AppContext);
};