import {
  CheckBadgeIcon,
  ClockIcon,
  UserPlusIcon,
  UserMinusIcon,
} from '@heroicons/react/20/solid';
import { Card } from '@urbansportsclub/components/Card';
import { Text } from '@urbansportsclub/components/Text';
import { useAppContext } from 'context/AppContext';
import { useTranslation } from 'react-i18next';

function ListComponent(props: {
  icon: JSX.Element;
  heading: string;
  description: string;
  bgColor?: string;
}) {
  return (
    <li className="flex gap-x-4">
      <span>{props.icon}</span>
      <div className="flex flex-col">
        <Text as="span" weight="bold" intent="md" className='text-black-midnight-light-500'>
          {props.heading}
        </Text>
        <Text as="span" intent="md">
            {props.description}
        </Text>
      </div>
      
    </li>
  );
}

function Video() {
  const { language }  = useAppContext();

  function getUrl() {
    if (language === 'de') {
      return 'https://player.vimeo.com/video/1000739976?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    } else {
      return 'https://player.vimeo.com/video/1000729262?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    }
  }
  return (
    <>
      <div className='sm:hidden grid grid-cols-1 justify-items-center mt-4'>
        <iframe
          src={getUrl()}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="HR Integration System - English"
          width="400"
          height="200"
        ></iframe>
      </div>
      <div className='hidden sm:grid lg:hidden grid grid-cols-1 justify-items-center'>
        <iframe
          src={getUrl()}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="HR Integration System - English"
          width="100%"
          height="400"
        ></iframe>
      </div>
      <div className='hidden lg:grid xl:hidden sm:grid-cols-1 sm:justify-items-center md:ml-auto'>
        <iframe 
        src={getUrl()} 
        width="600" 
        height="500"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
        title="HR Integration System - Deutsch"
        />
      </div>
      <div className='hidden xl:grid sm:grid-cols-1 sm:justify-items-center md:ml-auto'>
        <iframe 
        src={getUrl()} 
        width="700" 
        height="500"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
        title="HR Integration System - Deutsch"
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
}

function About() {
  const { t } = useTranslation();


  return (
    <div className='overflow-hidden lg:overflow-visible lg:px-0'>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:gap-x-0 lg:max-w-none lg:items-start lg:gap-y-4">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:grid-cols-2 lg:gap-x-8">
          <div className="lg:max-w-lg space-y-2">
            <Text as="h1" intent="xl" weight="bold">
              { t('hrwizard.about.title') }
            </Text>
            <Text as="p" intent="md">
              { t('hrwizard.about.subtitle') }
            </Text>
          </div>
        </div>
        <div className="lg:-mb-11 lg:-mt-12 lg:sticky lg:top-0 lg:col-start-2 lg:row-span-2 lg:row-start-1">
          <Video />
        </div>
        <div className="mt-4 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:grid-cols-2 lg:gap-x-8">
          <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul className="space-y-8 text-gray-600">
                <ListComponent
                  icon={
                    <UserPlusIcon
                      aria-hidden="true"
                      className="mt-0.5 h-6 w-6 text-blue-respect-500 flex-none"
                    />
                  }
                  heading={ t('hrwizard.about.employee_approval.title') }
                  description={ t('hrwizard.about.employee_approval.description') }
                />
                <ListComponent
                  icon={
                    <UserMinusIcon
                      aria-hidden="true"
                      className="mt-0.5 h-6 w-6 text-blue-respect-500 flex-none"
                    />
                  }
                  heading={ t('hrwizard.about.employee_cancellation.title') }
                  description={ t('hrwizard.about.employee_cancellation.description') }
                />
                <li className="flex gap-x-3">
                  <Card className="bg-grey-light-500 p-4 rounded-md">
                    <Text as="p" intent="sm">
                      { t('hrwizard.about.card') }
                    </Text>
                  </Card>
                </li>
                <ListComponent
                  icon={
                    <ClockIcon
                      aria-hidden="true"
                      className="mt-0.5 h-6 w-6 text-green-freedom-500 flex-none"
                    />
                  }
                  heading={ t('hrwizard.about.save_time.title') }
                  description={ t('hrwizard.about.save_time.description') }
                  bgColor="bg-green-freedom-50"
                />
                <ListComponent
                  icon={
                    <CheckBadgeIcon
                      aria-hidden="true"
                      className="mt-0.5 h-6 w-6 text-purple-community-500 flex-none"
                    />
                  }
                  heading={ t('hrwizard.about.real_time_update.title') }
                  description={ t('hrwizard.about.real_time_update.description') }
                  bgColor="bg-purple-community-50"
                />
              </ul>
            </div>
        </div>
      </div>
     </div>
  );
}

export default About;