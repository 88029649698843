import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { useTranslation } from 'react-i18next';

const onboardingEvents: ButtonLayerEvent = {
  item: 'OnBoarding Initialized',
  details: 'On Boarding Initialized from banner',
  origin: 'OnBoardingBanner',
};

const onboardingOnMouseEnterEvents: ButtonLayerEvent  = {
  item: 'OnBoarding OnHover',
  details: 'On Boarding OnHover from banner',
  origin: 'OnBoardingBanner',
};

function OnBoardingBar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return <div
    className='flex items-center text-white text-sm sm:text-base cursor-pointer'
    onClick={() => {
      pushToButtonDataLayer(onboardingEvents);
      navigate('/onboarding');
    }}
    onMouseEnter={() => {
      pushToButtonDataLayer(onboardingOnMouseEnterEvents);
    }}
  >
    <span>
      { t('onboarding.banner') }
    </span>
    <ChevronRightIcon
      className='h-8 w-8 sm:h-6 sm:w-6'
      aria-hidden='true'
    />
  </div>;
}

export default OnBoardingBar;
