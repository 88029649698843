import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import Separator from 'components/atoms/Separator';
import Button from 'components/atoms/Button';

import { DataLayerEvents, EVENT_GROUP_NAME, pushDataLayerEvent } from 'utils/dataLayer';
import { AuthFlow } from 'utils/constants';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  passwordResetSuccess: { event_group: EVENT_GROUP_NAME, event_name: 'password_reset_succeeded' },
};

const PasswordSet = (): JSX.Element => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const flow = searchParams.get('flow');

  useEffect(() => {
    if (flow === AuthFlow.PASSWORD) {
      pushDataLayerEvent(DATA_LAYER_EVENTS.passwordResetSuccess);
    }
  }, [flow]);

  return (
    <SingleColumnLayout>
      <SimpleSection title={t('password_set.title')}>
        <p>{t('password_set.description')}</p>
        <Separator offset={24} />
        <Button to="/login" wide text={t('password_set.button')} />
      </SimpleSection>
    </SingleColumnLayout>
  );
};

export default PasswordSet;
