import { Button } from '@urbansportsclub/components/Button';
import { Input } from '@urbansportsclub/components/Input';
import { Label } from '@urbansportsclub/components/Label';
import { Dropdown } from '@urbansportsclub/components/Dropdown';
import { DropdownContent } from '@urbansportsclub/components/Dropdown/Content';
import { DropdownItem } from '@urbansportsclub/components/Dropdown/Item';
import { DropdownTrigger } from '@urbansportsclub/components/Dropdown/Trigger';
import { Textarea } from '@urbansportsclub/components/Textarea';
import { useCallback, useContext, useEffect, useState } from 'react';
import API from 'utils/api';
import { AxiosResponse } from 'axios';
import { CompanyContext } from 'contexts/CompanyContext';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import { useTranslation } from 'react-i18next';
import { FaqContactUsCancelEvent, FaqContactUsEvent, FaqContactUsSubmitEvent } from 'components/pages/Support/SupportEvents';

interface CustomField {
  id: string;
  raw_name: string;
  name: string;
  value: string;
}
interface TicketField {
  title: string;
  description: string;
  id: string;
  custom_field_options: CustomField[];
}

interface Reason {
  ticket_field: TicketField;
}
export default function ContactUs() {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [reasons, setReasons] = useState<CustomField[]>([]);
  const { company } = useContext(CompanyContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();

  const getReasonList = useCallback(async () => {
    try {
      const response: AxiosResponse<Reason> = await API.get(
        '/companies/reasons-for-contact',
      );
      setReasons(response.data.ticket_field.custom_field_options);
    } catch (error) {
      return [];
    }
  }, []);

  useEffect(() => {
    getReasonList();
  }, [getReasonList]);


  const getTextForButton = useCallback(() => {
    if (selectedReason) {
      return reasons.find((reason) => reason.value === selectedReason)?.name.replace('::', ' - ') ?? 'Select Reason';
    }

    return 'Select Reason';
  }, [reasons, selectedReason]);

  const createTicket = useCallback(async () => {
    try {
      setLoading(true);
      await API.post(`/companies/${company?.id}/create-ticket`, {
        reasonForContact: selectedReason,
        subject,
        comment,
      });
      setLoading(false);
      setIsSubmitted(true);
      FaqContactUsSubmitEvent(getTextForButton());
      nullFormValues();

    } catch (error) {}
  }, [selectedReason, subject, comment, company, getTextForButton]);

  function onSubjectChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSubject(e.target.value);
  }

  function onCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setComment(e.target.value);
  }

  function onClose() {
    setOpen(false);
    setIsSubmitted(false);
  }

  function getTitle() {
    if (isSubmitted) {
      return t('support.contact_us.input_success.title');
    }

    return t('support.contact_us.title');
  }

  function getDescription() {
    if (isSubmitted) {
      return t('support.contact_us.input_success.description');
    }

    return t('support.contact_us.description');
  }

  function nullFormValues() {
    setComment('');
    setSubject('');
    setSelectedReason('');
  }

  function onCancel() {
    nullFormValues();
    FaqContactUsCancelEvent();
    setOpen(false);
  }

  return (
    <>
      <Button variant="secondary" onClick={() => {
        FaqContactUsEvent();
        setOpen(true);
      }}>
        {t('support.contact_us.button')}
      </Button>
      <Dialog
        title={getTitle()}
        description={getDescription()}
        open={open}
        onCancel={!isSubmitted ? onCancel : undefined}
        onConfirm={!isSubmitted ? createTicket : onClose}
        onCancelText={t('support.contact_us.input_cancel')}
        onConfirmText={!isSubmitted ? t('support.contact_us.input_submit') : t('support.contact_us.input_success.button')}
        variants={'primary'}
        badge={isSubmitted ? 'primary' : undefined}
        loading={loading}
        disabled={(!selectedReason || !subject || !comment) && !isSubmitted}
      >
        {!isSubmitted && (
          <div className="flex flex-col space-y-4 mt-4">
            <div className="flex flex-col space-y-1">
              <Label>{t('support.contact_us.input_reason')}</Label>
              
                <Dropdown
                defaultValue={selectedReason}
                value={selectedReason}
                variant='menu'
                mode='light'
                className='w-full border border-grey-dark-500 rounded-md'
              >
                <DropdownTrigger className='w-full inline-flex justify-between items-center'>
                  {getTextForButton()}
                </DropdownTrigger>
                <DropdownContent className='z-10'>
                  {reasons.map((reason) => (
                    <DropdownItem key={reason.id} value={reason.value} onClick={() => setSelectedReason(reason.value)}>
                      {t('reason_contact.' + reason.id).replace('::', ' - ')}
                    </DropdownItem>
                  ))}
                </DropdownContent>
              </Dropdown>
            </div>
            <div className="flex flex-col space-y-1">
              <Label>{t('support.contact_us.input_subject')}</Label>
              <Input
                placeholder="Subject"
                className="h-10 text-base"
                value={subject}
                onChange={onSubjectChange}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <Label>{t('support.contact_us.input_description')}</Label>
              <Textarea placeholder='Description' value={comment} onChange={onCommentChange} className='text-base'/>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
