import { tv } from 'tailwind-variants';


export const base = tv({
  base: 'flex flex-col',
  variants: {
    center: {
      true: 'items-center',
    },
  },
});

export const description = tv({
  base: 'pt-4',
  variants: {
    center: {
      true: 'text-center',
    },
  },
});

export const heading = tv({
  base: '',
  variants: {
    center: {
      true: 'text-center',
    },
  },
});

export const subDescription = tv({
  base: '',
  variants: {
    center: {
      true: 'text-center',
    },
  },
});