import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import { getRequestConfig } from './requestConfig';

export interface ResponseData<T = any> {
  message?: string;
  code?: number;
  data: T;
  status?: number;
}

export interface ResponseError<T = any> {
  message: string;
  code?: number;
  data?: T;
  'hydra:description'?: string;
  'hydra:title'?: string;
  status?: number;
}

const API = {
  get: async <T>(
    url: string,
    params?: Record<string, unknown>,
    apiConfig?: AxiosRequestConfig,
  ):Promise<AxiosResponse<T>> => {
    const config = getRequestConfig(apiConfig);
    config.params = { ...params };

    const request = axios.get<T>(url, config);

    return request;
  },

  post: async <T> (
    url: string,
    data: Record<string, unknown>,
    apiConfig?: AxiosRequestConfig,
  ):Promise<AxiosResponse<T>> => {
    const config = getRequestConfig(apiConfig);

    const request = await axios.post<T>(url, data, config);
    return request;
  },

  put: async <T>(
    url: string,
    data: Record<string, unknown>,
    apiConfig?: AxiosRequestConfig,
  ):Promise<AxiosResponse<T>> => {
    const config = getRequestConfig(apiConfig);

    const request = await axios.put<T>(url, JSON.stringify(data), config);
    return request;
  },

  patch: async <T>(
    url: string,
    data: Record<string, unknown>,
    apiConfig?: AxiosRequestConfig,
  ):Promise<AxiosResponse<T>> => {
    const config = getRequestConfig(apiConfig);

    const request = await axios.patch<T>(url, JSON.stringify(data), config);
    return request;
  },

  deleteResource: async (url: string, apiConfig?: AxiosRequestConfig):Promise<AxiosResponse> => {
    const config = getRequestConfig(apiConfig);

    const request = axios.delete(url, config);
    return request;
  },
};

export default API;
