import { tv } from 'tailwind-variants';

export const loader = tv({
  base: 'animate-spin -ml-1 h-5 w-5',
  variants: {
    colors: {
      white: 'text-white-natural-500/95',
      blue: 'text-blue-respect-500/95',
    },
  },
  defaultVariants: {
    colors: 'white',
  },
});
